import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import useAuth from './components/hooks/useAuth';
import SettingsPage from './components/pages/SettingsPage';
import CoinInfoComponent from './components/pages/CoinInformation';
import BackTestAllCoins from './components/pages/BackTestHighsLowsForCoins';
import { Dashboard } from './components/pages/Homepage';
import { LoginPage } from './components/pages/LoginPage';
import { SalesPage } from './components/pages/SalesPage';
import MultiCoinBackTest from './components/pages/BackTestMultipleCoins';
import { AnalysisPage } from './components/pages/Analysis';

const isDev = process.env.NODE_ENV !== 'production';

const AppRoutes = () => {
  const { isLoggedIn } = useAuth({ useAuto: false });

  return (
    <Routes>
      <Route path="/" element={<SalesPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/dashboard"
        element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" replace />}
      />
      {isLoggedIn && (
        <>
          <Route path="/settings" element={<SettingsPage />} />
          <Route
            path="/awscoin/:coinID/:tradeNotation"
            element={<CoinInfoComponent />}
          />
        </>
      )}
      {isDev && isLoggedIn && (
        <>
          <Route path="/backtest" element={<MultiCoinBackTest />} />
          <Route path="/analysis" element={<AnalysisPage />} />
          <Route path="/BackTestHighLows" element={<BackTestAllCoins />} />
          <Route
            path="/BackTestMultipleCoins"
            element={<MultiCoinBackTest />}
          />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
