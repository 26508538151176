/* eslint-disable no-param-reassign */
import {
  BarData,
  BarPrice,
  ChartOptions,
  CrosshairMode,
  DeepPartial,
  LineStyle,
  LineWidth,
  PriceFormat,
} from 'lightweight-charts';
import {
  AWSCoinInformation,
  MainChartProps,
  LayeredBoughtDetails,
  LayeredBuyBidDetails,
  TradeNotations,
  LinesInChart,
} from '../types';
import {
  calculateTotalBalance,
  getPercentageDiff,
} from '../utils/calculations/percentageAndprice';
import {
  createPriceLineInChart,
  setAlertPriceLine,
  setBreakEvenLine,
  setBuyBidsLineInChart,
  setHighPriceLine,
  setLowPriceLine,
  setMarkerInTime,
} from './linesAndMarkers';

export const chartLayoutStyle: DeepPartial<ChartOptions> = {
  layout: {
    backgroundColor: '#1D1F20',
    textColor: '#fff',
  },
  grid: {
    vertLines: {
      visible: false,
    },
    horzLines: {
      color: '#1D1F20',
    },
  },
  crosshair: {
    vertLine: {
      width: 1,
      style: 2,
      visible: true,
      labelVisible: false,
    },
    horzLine: {
      width: 1,
      style: 2,
      visible: true,
      labelVisible: true,
    },
    mode: 0,
  },
};

export const chartLayoutStyleDark = (
  backgroundColor = '#000000'
): DeepPartial<ChartOptions> => ({
  layout: {
    backgroundColor,
    textColor: '#E3CC8C',
  },
  grid: {
    vertLines: {
      visible: false,
    },
    horzLines: {
      visible: false,
    },
  },
  crosshair: {
    mode: CrosshairMode.Normal,
    vertLine: {
      visible: false,
      labelVisible: false,
    },
    horzLine: {
      visible: false,
      labelVisible: false,
    },
  },
  timeScale: {
    borderVisible: false,
    rightOffset: 40,
  },
  rightPriceScale: {
    borderVisible: false,
    scaleMargins: {
      top: 0.2,
      bottom: 0.1,
    },
  },
});

export const timeScale = {
  // show time in x-axis
  timeVisible: true,
  secondsVisible: false,
  visible: true,
};

type OptionProp = {
  priceLineVisible: boolean;
  priceLineWidth: LineWidth;
  priceLineColor: string;
  priceLineStyle: LineStyle;
  priceFormat: PriceFormat;
};

export const chartSeriesOptions: OptionProp = {
  priceLineVisible: false,
  priceLineWidth: 1,
  priceLineColor: 'green',
  priceLineStyle: 4,
  priceFormat: {
    minMove: 0.00000001, // minimum move that is to be shown in the x-axis
    type: 'custom',
    formatter: (price: BarPrice) =>
      parseFloat(price as unknown as string).toFixed(8),
    // show 8 decimal places in the price
  },
};

const deletePriceLinesAndAddNewOnes = (
  chart: MainChartProps,
  priceLines: LinesInChart,
  currentPrice: number,
  relativeLow: number,
  relativeHigh: number,
  alertPrice: number,
  breakEvenStopLoss: boolean = false,
  layeredBuyBids: LayeredBuyBidDetails | undefined,
  layeredBuyBoughts: LayeredBoughtDetails | undefined
) => {
  // add lines and markets to chart
  setLowPriceLine(relativeLow, priceLines, chart.mainCandlestickSeries);
  setHighPriceLine(relativeHigh, priceLines, chart.mainCandlestickSeries);

  if (alertPrice) {
    const percentage = getPercentageDiff(alertPrice, relativeHigh);
    setAlertPriceLine(
      alertPrice,
      priceLines,
      percentage,
      chart.mainCandlestickSeries
    );
  }

  if (layeredBuyBids && chart.mainCandlestickSeries) {
    setBuyBidsLineInChart(
      layeredBuyBids,
      relativeHigh,
      priceLines,
      chart.mainCandlestickSeries
    );
  }

  if (layeredBuyBoughts) {
    // calculate breakeven price and add a priceLine
    setBreakEvenLine(
      chart.mainCandlestickSeries,
      layeredBuyBoughts,
      currentPrice,
      breakEvenStopLoss,
      priceLines
    );

    layeredBuyBoughts.forEach((bought) => {
      if (bought.stoploss && !bought.sellTime) {
        const { stoplossPrice, buyColor, profitSecured, buyPercent } = bought;
        const stopLossLine = createPriceLineInChart(
          chart.mainCandlestickSeries,
          stoplossPrice!,
          buyColor,
          `${buyPercent}% stoploss ${
            profitSecured && `${profitSecured.toFixed(1)}% profit`
          }`,
          1
        );

        if (stopLossLine) {
          if (priceLines.boughtLines) {
            priceLines.boughtLines[bought.id] = {
              stopLossLine,
            };
          }
        }
      }
    });
  }

  return { chart, updatePriceLines: priceLines };
};

export const preparechart = (
  tradeNotation: TradeNotations,
  notation: 'btcPair' | 'usdPair',
  coinDetails: AWSCoinInformation,
  coinHistData: BarData[],
  priceLines: LinesInChart,
  chart?: MainChartProps,
  setPriceLines?: React.Dispatch<React.SetStateAction<LinesInChart>>,
  setMainChart?: React.Dispatch<
    React.SetStateAction<MainChartProps | undefined>
  >,
  showPnL?: boolean,
  showTrades = true
) => {
  if (!chart) return;

  const {
    currentPrice,
    relativeLow,
    relativeHigh,
    alertPrice,
    layeredBuyBids,
    layeredBuyBoughts,
    initialBalance,
    reservedBalance,
    highsAndLows,
    breakEvenStopLoss,
  } = coinDetails;

  chart.mainCandlestickSeries.setData(coinHistData);
  chart.mainChart.timeScale().scrollToPosition(30, false);
  const updateChartAndPriceLines = deletePriceLinesAndAddNewOnes(
    chart,
    priceLines,
    currentPrice,
    relativeLow,
    relativeHigh,
    alertPrice,
    breakEvenStopLoss,
    layeredBuyBids.items,
    layeredBuyBoughts.items
  );
  const runningProfitLoss = calculateTotalBalance(
    currentPrice,
    initialBalance,
    layeredBuyBoughts.items || []
  );
  // TODO market is being set 1 day more then when it should be. below is a temporary fix
  //  time: (parseInt(layeredBuyBoughts. as string, 10) - 86400) as UTCTimestamp

  const trimmedHighsAndLows = highsAndLows?.filter(
    (point) => point.time >= (coinHistData[0].time as number)
  );

  setMarkerInTime(
    chart.mainCandlestickSeries,
    tradeNotation,
    layeredBuyBoughts.items,
    initialBalance,
    runningProfitLoss,
    reservedBalance,
    trimmedHighsAndLows,
    coinHistData[coinHistData.length - 1].time as number,
    coinHistData[coinHistData.length - 1].close,
    showPnL,
    showTrades
  );

  if (setMainChart && setPriceLines) {
    setMainChart(updateChartAndPriceLines.chart);
    setPriceLines(updateChartAndPriceLines.updatePriceLines);
  }
};
