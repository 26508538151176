import { Order } from 'ccxt';
import { Alerts, Coin } from '../API';
import { KeyForTradePairs, TradeNotations } from '../types';

// idea copied from https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
export const waitFor = (ms: number) => new Promise((r) => setTimeout(r, ms));
export const asyncForEach = async (
  array: string | unknown[],
  callback: (args: any, index: number, arr: any) => void
) => {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
};

export const revertCoinId = (coinID: string) => {
  const revertedCoinId = coinID
    .replace('terra', 'terra-luna')
    .replace('aave-new', 'aave-aave')
    .replace('iost-iost', 'iost-iostoken')
    .replace('elrond', 'elrond-egld')
    .replace('compoundd', 'compound')
    .replace('theta-token', 'theta')
    .replace('republic-protocol', 'ren')
    .replace('polkadot', 'polkadot-new')
    .replace('yearnfinance', 'yearn-finance')
    .split('-');

  const mainID = revertedCoinId[0];
  revertedCoinId.shift();

  return `${revertedCoinId.join('-')}-${mainID}`;
};

export const stripUndefinedValue = (object: Order) =>
  JSON.parse(JSON.stringify(object));

export const getRainbowColor = () => {
  const size = 5;
  const rainbow = new Array(size);

  const sinToHex = (i: number, phase: number) => {
    const sin = Math.sin((Math.PI / size) * 2 * i + phase);
    const int = Math.floor(sin * 127) + 128;
    const hex = int.toString(16);

    return hex.length === 1 ? `0${hex}` : hex;
  };

  for (let i = 0; i < size; i++) {
    const red = sinToHex(i, (0 * Math.PI * 2) / 3); // 0   deg
    const blue = sinToHex(i, (1 * Math.PI * 2) / 3); // 120 deg
    const green = sinToHex(i, (2 * Math.PI * 2) / 3); // 240 deg

    rainbow[i] = `#${red}${green}${blue}`;
  }
  return rainbow;
};

export const getBinanceLinkForCoin = (
  coinID: string,
  notation: TradeNotations
) =>
  `https://www.binance.com/en/trade/${coinID.toUpperCase()}_${(notation ===
  'usd'
    ? 'USDT'
    : notation
  ).toUpperCase()}`;

export const addPair = (notation: TradeNotations): KeyForTradePairs =>
  `${notation}Pair` as KeyForTradePairs;

export const fetchOrder = (alert: Alerts) =>
  alert.buyType === 'buy' ? alert.buyOrder : alert.boughtOrder;

export const getPairDetail = (coin: Coin, coinID: string, notation: string) =>
  coin?.pairs?.items?.filter(
    (pair: any) => pair?.id === `${coinID}-${notation}`
  )[0];

export const formatNumberDE = (value: number): string => {
  const decimals = value < 1 ? 5 : 0;
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
  });

  return formatter.format(value);
};
