import { BarData, createChart } from 'lightweight-charts';
import React from 'react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {
  Container,
  Grid,
  Header,
  Label,
  Statistic,
  Step,
  Image,
  Checkbox,
  Segment,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import {
  AWSCoinInformation,
  LayeredBoughtDetails,
  AvgHighLowsPerc,
  MainChartProps,
  TradeNotations,
  LinesInChart,
} from '../../types';
import {
  averageHighsAndLows,
  calculateTotalPnLPercentage,
} from '../../utils/calculations/percentageAndprice';
import { revertCoinId } from '../../utils/utils';
import { MainHeader } from '../layout/Header';
import { AwsCoinForm } from '../SubComponents/forms/CoinForm';
import { AwsManualBuyForm } from '../SubComponents/forms/ManualBuyForm';
import { Percentage } from '../SubComponents/Percentage';
import { AwsOrdersTable } from '../SubComponents/tables/CoinOrdersTable';
import AwsDatabase from '../../database/Database';
import {
  chartLayoutStyle,
  chartSeriesOptions,
  preparechart,
  timeScale,
} from '../../chart-specifics/create-chart';
import { getCoinTotalInvestedAndProfit } from '../layout/Dashboard';
import { getHistoricalDataForCoin, historicalApiUrl } from '../../api/apiCalls';

const AwsDB = new AwsDatabase();

type TotalProfitLossProps = {
  boughts: LayeredBoughtDetails;
  currentPrice: number;
};

type Params = { coinID: string; tradeNotation: TradeNotations };
export const TotalProfitLoss: React.FC<TotalProfitLossProps> = ({
  boughts,
  currentPrice,
}) => {
  const { profitLoss } = calculateTotalPnLPercentage(boughts, currentPrice);

  if (Number.isNaN(profitLoss)) return null;

  return (
    <h1>
      <Percentage percentage={profitLoss}>{profitLoss.toFixed(2)}%</Percentage>
    </h1>
  );
};

type TotalTimeTakenProps = { coinDetails: AWSCoinInformation };
export const TotalTimeTaken: React.FC<TotalTimeTakenProps> = ({
  coinDetails,
}: TotalTimeTakenProps) => {
  const { layeredBuyBoughts, currentPrice } = coinDetails;
  const { avgTimeForAllTradeToComplete } = getCoinTotalInvestedAndProfit(
    layeredBuyBoughts.items,
    currentPrice
  );

  return (
    <h2 className="noMargin">{`${avgTimeForAllTradeToComplete.toFixed(
      0
    )} days`}</h2>
  );
};

const CoinInfoComponent: React.FC = (): JSX.Element | null => {
  const { coinID, tradeNotation } = useParams<Params>();
  if (!coinID || !tradeNotation) {
    throw new Error('No coinID or tradeNotation provided');
  }

  const linesInChart: LinesInChart = {
    highPriceLine: undefined,
    lowPriceLine: undefined,
    alertPriceLine: undefined,
    breakEvenLine: undefined,
    buyLines: undefined,
    boughtLines: undefined,
  };

  const [coinDetails, setCoinDetails] = React.useState<AWSCoinInformation>();
  const [coinHistData, setCoinHistData] = React.useState<BarData[]>();
  const [priceLines, setPriceLines] =
    React.useState<LinesInChart>(linesInChart);
  const [chartAndCandleStick, setMainChart] = React.useState<MainChartProps>();
  const [showTrades, setShowTrades] = React.useState<boolean>(true);
  const [highsAndLowsAvg, setHighsAndLowsAvg] =
    React.useState<AvgHighLowsPerc>();
  const notation = tradeNotation === 'btc' ? 'btcPair' : 'usdPair';
  const chartRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    const symbol = coinID.split('-')[0];
    const histURL = historicalApiUrl(symbol, tradeNotation, 'day', 1000);
    const histData = getHistoricalDataForCoin(histURL);
    const coinInfo = AwsDB.getCoinDetailsWithBuysAndBoughts(
      `${coinID}-${tradeNotation}`
    );

    coinInfo.then((data) => {
      if (data !== coinDetails) {
        setCoinDetails(data);
      }
    });

    histData.then((data) => {
      setCoinHistData(data);
    });
  }, []);

  React.useEffect(() => {
    if (
      chartRef.current &&
      coinDetails !== undefined &&
      coinHistData !== undefined
    ) {
      let chartWithSeries;
      const showPnL = false;
      if (!chartAndCandleStick) {
        const chart = createChart(chartRef.current, {
          width: chartRef.current.clientWidth,
          height: 800,
          timeScale,
        });
        chart.applyOptions({ ...chartLayoutStyle });
        const candlestickSeries = chart.addCandlestickSeries();
        candlestickSeries.applyOptions({ ...chartSeriesOptions });

        chartWithSeries = {
          mainChart: chart,
          mainCandlestickSeries: candlestickSeries,
        };
      }

      preparechart(
        tradeNotation,
        notation,
        coinDetails,
        coinHistData,
        priceLines,
        chartAndCandleStick || chartWithSeries,
        setPriceLines,
        setMainChart,
        showPnL,
        showTrades
      );

      setHighsAndLowsAvg(averageHighsAndLows(coinDetails.highsAndLows));
    }
  }, [coinDetails, coinHistData]);

  React.useEffect(() => {
    const resizeChart = () => {
      if (chartAndCandleStick && chartRef.current) {
        chartAndCandleStick.mainChart.resize(chartRef.current.clientWidth, 800);
      }
    };

    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  });

  React.useEffect(() => {
    if (coinDetails && coinHistData) {
      preparechart(
        tradeNotation,
        notation,
        coinDetails,
        coinHistData,
        priceLines,
        chartAndCandleStick,
        setPriceLines,
        setMainChart,
        false,
        showTrades
      );
    }
  }, [showTrades]);

  if (!coinDetails?.Coin.name) return null;

  return (
    <>
      <MainHeader />
      <Container>
        <Grid container style={{ marginTop: 0 }}>
          <Grid.Row>
            <Statistic.Group size="tiny" className="marginAuto">
              <Statistic>
                <Statistic.Value>
                  <Image
                    src={`https://cryptologos.cc/logos/${revertCoinId(
                      coinID
                    )}-logo.svg?v=010`}
                    inline
                    circular
                    width="30"
                    style={{ verticalAlign: 'top', marginRight: '4px' }}
                  />
                  {coinDetails?.Coin.name}
                </Statistic.Value>
                <Label className="marginTop" color="olive">
                  Rank : {coinDetails?.Coin.rank}
                </Label>
              </Statistic>
            </Statistic.Group>
          </Grid.Row>
        </Grid>
        <Grid divided="vertically">
          <Grid.Row className="noPadding">
            <Grid.Column stretched className="noMargin">
              <div style={{ background: 'black' }} ref={chartRef} />
            </Grid.Column>
          </Grid.Row>
          <Step.Group fluid className="noMargin">
            <Step
              icon="bitcoin"
              description={
                <TotalProfitLoss
                  boughts={coinDetails.layeredBuyBoughts.items}
                  currentPrice={coinDetails.currentPrice}
                />
              }
            />
            <Step
              className="hide-on-mobile"
              icon="thumbs up"
              title={<TotalTimeTaken coinDetails={coinDetails} />}
              description="for sold orders"
            />
            <Step
              className="hide-on-mobile center"
              icon="angle double down"
              title={
                highsAndLowsAvg && (
                  <b>
                    Low {highsAndLowsAvg?.lowLows}
                    <Header as="b" size="medium" color="olive">
                      {' '}
                      |{' '}
                    </Header>{' '}
                    Med <Label color="olive">{highsAndLowsAvg?.avgLows}</Label>
                  </b>
                )
              }
              description="Drop from the highs"
              active
            />
            <Step
              className="hide-on-mobile"
              icon="angle double up"
              title={
                highsAndLowsAvg && (
                  <b>
                    {`Low ${highsAndLowsAvg?.lowHighs}`}{' '}
                    <Header as="b" size="medium" color="olive">
                      {' '}
                      |{' '}
                    </Header>{' '}
                    Med{' '}
                    <Label color="olive">
                      {highsAndLowsAvg?.avgHighs &&
                      highsAndLowsAvg?.avgHighs > 200
                        ? 200
                        : highsAndLowsAvg?.avgHighs}
                    </Label>
                  </b>
                )
              }
              description="Rise from the lows"
            />
            <Step
              description={
                <Segment compact basic>
                  <Label as="span" basic size="large" className="noBorder">
                    show Trades ?
                  </Label>
                  <Checkbox
                    toggle
                    className="vmiddle"
                    id="showTrades"
                    name="showTrades"
                    defaultChecked={showTrades}
                    onChange={() => setShowTrades(!showTrades)}
                  />
                </Segment>
              }
            />
          </Step.Group>

          <AwsCoinForm
            coinID={coinID}
            notation={tradeNotation}
            higestPrice={coinDetails.higestPrice}
            relativeHigh={coinDetails.relativeHigh}
            paperTrade={coinDetails.dontTrade}
          />

          <AwsManualBuyForm
            coinID={coinID}
            notation={tradeNotation}
            currentPrice={coinDetails.currentPrice}
            paperTrade={coinDetails.dontTrade}
            highPrice={coinDetails.higestPrice}
          />

          <AwsOrdersTable
            currentPrice={coinDetails.currentPrice}
            bids={coinDetails.layeredBuyBids.items}
            boughts={coinDetails.layeredBuyBoughts.items}
            isTradePairBtc={tradeNotation === 'btc'}
          />
        </Grid>
      </Container>
    </>
  );
};

export default CoinInfoComponent;
