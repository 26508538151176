import React from 'react';
import AwsDatabase from '../../database/Database';
import { Balances, Coin } from '../../API';
const AwsDB = new AwsDatabase();

export const useAllCoinData = () => {
  const [data, setAllCoinsDataFromDB] = React.useState<Coin[]>([]);
  const [balance, setBalance] = React.useState<Balances>();

  const getCoinDataAndBalance = () => {
    const getAllTopCoinsData = async () => {
      const awsdata: Coin[] =
        await AwsDB.getCoinsWithPairAndOrdersForDashboard();

      setAllCoinsDataFromDB(awsdata);
    };

    const getBalance = async () => {
      const balance = await AwsDB.getBalances();
      console.log('balance', balance);
      setBalance(balance);
    };

    getAllTopCoinsData();
    getBalance();
  };

  React.useEffect(() => {
    getCoinDataAndBalance();
  }, []);

  return { data, balance, getCoinDataAndBalance };
};
