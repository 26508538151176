import { Coin, PairInfo, OrderBoughts, HighLowPrice } from '../API';
import { geFirstXCoins } from '../api/apiCalls';
import {
  CoinpaprikaCoin,
  DataProp,
  LayeredBuyBid,
  LinesInChart,
} from '../types';
import AwsDatabase from '../database/Database';
import axios, { AxiosResponse } from 'axios';
import { setRecentHighLowPriceAndLine } from '../chart-specifics/coinCalculation';
import {
  calculatePercentageStatistics,
  setLowAndHighPrice,
} from './calculations/percentageAndprice';
import { asyncForEach, waitFor, getPairDetail } from './utils';
import { convertToMillis } from './calculations/timeCalculation';

const AwsDB = new AwsDatabase();

const formatPrice = (price: number, notation: string) => {
  if (notation === 'usd') {
    return Number(price.toFixed(8));
  }
  return price;
};

export const addAllTimeHighLowToCoins = async (
  notation: 'usd' | 'btc',
  coinsToBeAdded: Coin[] | string[],
  isNewCoin: boolean = false
) => {
  const shouldAddForAllCoins = coinsToBeAdded.length === 0;
  let coinsToAdd = coinsToBeAdded;

  if (shouldAddForAllCoins) {
    const awsdata: Coin[] = await AwsDB.getCoinsWithPairAndOrdersForDashboard();
    coinsToAdd = awsdata;
  }

  let coinMarketData: AxiosResponse<any>;
  if (isNewCoin) {
    const ALL_COIN_TICKERS =
      'https://api.coinpaprika.com/v1/tickers?quotes=USD,BTC';
    coinMarketData = await axios.get(ALL_COIN_TICKERS);
  }

  await asyncForEach(coinsToAdd, async (coin) => {
    await waitFor(1000);
    const id = shouldAddForAllCoins ? coin.id : coin;
    const dontTrade = coin?.dontTrade;
    const pair = getPairDetail(coin, id, notation);
    const isDontTrade = shouldAddForAllCoins && dontTrade;
    const hasHighsAndLows = shouldAddForAllCoins && pair?.highsAndLows;

    if (isDontTrade) return;
    if (hasHighsAndLows && !isNewCoin) {
      console.log(`${id} already has highAndLows saved. no need again!`);
      return;
    }

    let coinSymbol = id.split('-')[0];
    if (coinSymbol === 'featch') {
      coinSymbol = 'fet';
    }

    const historicalEndPoint = `https://min-api.cryptocompare.com/data/v2/histoday?fsym=${coinSymbol}&tsym=${notation}&limit=2000`;

    try {
      const historyForCoin = await axios.get(historicalEndPoint);
      const historyData = historyForCoin.data.Data.Data;
      const filteredData = historyData.filter(
        (d: { high: number }) => d.high > 0
      );

      let highPrice: number | null = null;
      let lowFromHighPrice: number | null = null;
      let highsLows: Omit<HighLowPrice, '__typename'>[] = [];
      const alertTriggered: boolean = false;
      let alertPrice: number | null = null;
      const linesInChart: LinesInChart = {
        highPriceLine: undefined,
        lowPriceLine: undefined,
        alertPriceLine: undefined,
        breakEvenLine: undefined,
        buyLines: undefined,
        boughtLines: undefined,
      };

      filteredData.forEach((tick: DataProp) => {
        const sanitizedData = tick;

        const {
          newHighPrice,
          newLowFromHighPrice,
          newAlertPrice,
          previousLowPrice,
        } = setRecentHighLowPriceAndLine(
          sanitizedData.high,
          sanitizedData,
          highPrice || sanitizedData.high,
          lowFromHighPrice || sanitizedData.low,
          [],
          alertTriggered,
          alertPrice,
          calculatePercentageStatistics(
            pair?.highsAndLows as Omit<HighLowPrice, '__typename'>[]
          ),
          highsLows,
          linesInChart
        );

        highPrice = newHighPrice;
        lowFromHighPrice = newLowFromHighPrice;
        alertPrice = newAlertPrice;

        highsLows = setLowAndHighPrice(
          highsLows,
          highPrice as number,
          previousLowPrice,
          convertToMillis(tick.time as number)
        );
      });

      if (isNewCoin) {
        const detailsForCoin = coinMarketData.data.filter(
          (c: { id: string }) => c.id === id
        );

        const higestValueForCoin =
          id === 'btc-bitcoin'
            ? detailsForCoin[0]?.quotes.USD.ath_price
            : detailsForCoin[0]?.quotes[notation.toUpperCase()].ath_price;

        const currentPriceForCoin =
          id === 'btc-bitcoin'
            ? detailsForCoin[0]?.quotes.USD.price
            : detailsForCoin[0]?.quotes[notation.toUpperCase()].price;

        await AwsDB.createPairInfo({
          id: `${id}-${notation}`,
          notation,
          breakEvenStopLoss: false,
          currentPrice: formatPrice(currentPriceForCoin, notation),
          higestPrice: formatPrice(higestValueForCoin, notation),
          highsAndLows: highsLows,
          initialBalance: 0,
          dontTrade: false,
          relativeLow: formatPrice(lowFromHighPrice || 0, notation),
          relativeHigh: formatPrice(highPrice || 0, notation),
          reservedBalance: 0,
          baseType: 'pairInfo',
          pairInfoCoinId: id,
        });
      } else {
        await AwsDB.updatePairInfoPrices({
          id: `${id}-${notation}`.toLowerCase(),
          highsAndLows: highsLows,
        });
      }

      console.log(
        `${
          isNewCoin ? 'Created' : 'Updated'
        } pair info for --> ${id}-${notation}`
      );
    } catch (error) {
      console.log(`error occurred! for --> ${id}`, error);
    }
  });
};

export const addNewFiftyCoinsToDB = async (
  dbCoins: Coin[],
  coinsToBeAdded: string[] = []
) => {
  const listAllCoins = 'https://api.coinpaprika.com/v1/coins';
  const fiftyCoinsData: CoinpaprikaCoin[] = await geFirstXCoins(
    listAllCoins,
    100
  );
  const newlyAddedCoins: string[] = [];

  for (const coin of fiftyCoinsData) {
    const { id } = coin;
    const coinExistsinDb = dbCoins.find((dbCoin) => dbCoin.id === id);

    if (coinExistsinDb && coinExistsinDb.rank !== coin.rank) {
      await AwsDB.updateCoin({ id: coin.id, rank: coin.rank });
      continue;
    }

    if (coinsToBeAdded.includes(coin.id) && !coinExistsinDb) {
      await AwsDB.createCoin(coin);
      newlyAddedCoins.push(coin.id);
    }
  }

  if (newlyAddedCoins.length > 0) {
    console.log('Adding pairs and high/lows for new coins...');
    await addAllTimeHighLowToCoins('btc', newlyAddedCoins, true);
    await addAllTimeHighLowToCoins('usd', newlyAddedCoins, true);
  }

  return newlyAddedCoins;
};

export const deleteDontTradeCoinsFromDB = async (data: Coin[]) => {
  for (const coin of data) {
    const pairs = coin?.pairs?.items;
    if (!pairs || pairs.length === 0) {
      console.log('no pairs found');
      console.log('deleting coin', coin.id);
      await AwsDB.deleteCoin(coin.id);
    }

    for (const pair of pairs as PairInfo[]) {
      const { dontTrade, layeredBuyBids, layeredBuyBoughts } = pair;

      if (dontTrade) {
        console.log('initiating delete for pair', pair.id);
        const bids = layeredBuyBids?.items;
        const boughts = layeredBuyBoughts?.items;

        if (bids && bids.length > 0) {
          for (const bid of bids as unknown as LayeredBuyBid[]) {
            console.log('deleting bid', bid.id);
            await AwsDB.deleteCoinBidOrder(bid.id);
          }
        }

        if (boughts && boughts.length > 0) {
          for (const bought of boughts as unknown as OrderBoughts[]) {
            console.log('deleting bought', bought.id);
            await AwsDB.deleteCoinBoughtOrder(bought.id);
          }
        }

        await AwsDB.deleteCoinPairInfo(pair.id);
        await AwsDB.deleteCoin(coin.id);
      }
    }
  }
};

// Add new function to refresh rankings
export const refreshCoinRankings = async (dbCoins: Coin[]) => {
  try {
    const listAllCoins = 'https://api.coinpaprika.com/v1/coins';
    const fiftyCoinsData: CoinpaprikaCoin[] = await geFirstXCoins(
      listAllCoins,
      100
    );
    const updatedCoins: string[] = [];

    for (const coin of fiftyCoinsData) {
      const { id, rank } = coin;
      const coinInDb = dbCoins.find((dbCoin) => dbCoin.id === id);

      if (coinInDb && coinInDb.rank !== rank) {
        await AwsDB.updateCoin({ id, rank });
        updatedCoins.push(id);
      }
    }

    return updatedCoins;
  } catch (error) {
    console.error('Error refreshing coin rankings:', error);
    throw error;
  }
};

interface CMCCoin {
  id: number;
  name: string;
  cmc_rank: number;
  symbol: string;
  slug: string;
}

interface CMCResponse {
  data: CMCCoin[];
}

export const refreshRankingsFromCMC = async (dbCoins: Coin[]) => {
  try {
    const CMC_API_KEY = process.env.REACT_APP_CMC_API_KEY;
    if (!CMC_API_KEY) {
      throw new Error('CoinMarketCap API key not found');
    }

    const response = await fetch(
      'https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?limit=100',
      {
        headers: {
          'X-CMC_PRO_API_KEY': CMC_API_KEY,
        },
      }
    );

    const { data: cmcCoins }: CMCResponse = await response.json();
    const updatedCoins: string[] = [];

    for (const coin of cmcCoins) {
      const { slug, cmc_rank, symbol } = coin;
      const id = `${symbol.toLowerCase()}-${slug.toLowerCase()}`;
      const coinInDb = dbCoins.find((dbCoin) => dbCoin.id === id);

      if (coinInDb && coinInDb.rank !== cmc_rank) {
        await AwsDB.updateCoin({ id, rank: cmc_rank });
        updatedCoins.push(id);
      }
    }

    return updatedCoins;
  } catch (error) {
    console.error('Error refreshing coin rankings:', error);
    throw error;
  }
};
