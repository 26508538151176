import { useEffect, useState } from 'react';

export const useHideColumnsOnMobile = (columnsToHide: Array<string>) => {
  const [hiddenColumns, setHiddenColumns] = useState<any>([]);

  useEffect(() => {
    const updateHiddenColumns = () => {
      if (window.innerWidth <= 768) {
        setHiddenColumns(columnsToHide);
      } else {
        setHiddenColumns([]);
      }
    };

    window.addEventListener('resize', updateHiddenColumns);
    updateHiddenColumns(); // call once immediately to set initial state

    return () => window.removeEventListener('resize', updateHiddenColumns); // cleanup on unmount
  }, []);

  return hiddenColumns;
};
