import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { Balances, OrderBoughts, PairInfo } from '../../../API';
import {
  hasRealTrades,
  isRealBoughtOrder,
} from '../../../chart-specifics/coinCalculation';

type BalanceData = {
  name: string;
  value: number;
};

interface BalancePieChartProps {
  notationBasedCoins: PairInfo[];
  balance: Balances;
  notation: string;
}

const generateColors = (count: number): string[] => {
  return Array.from({ length: count }, (_, i) => {
    const hue = (i * 137.508) % 360;
    return `hsl(${hue}, 85%, 60%)`;
  });
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
  payload,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#E3CC8C"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${name} ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const BalancePieChart: React.FC<BalancePieChartProps> = ({
  notationBasedCoins,
  balance,
  notation,
}) => {
  const prepareChartData = (): BalanceData[] => {
    if (!notationBasedCoins || !balance) return [];

    return notationBasedCoins
      .map((coin) => {
        const name = coin.id.split('-')[0].toUpperCase();

        const validOrders =
          coin.layeredBuyBoughts?.items?.filter(
            (order): order is OrderBoughts =>
              Boolean(isRealBoughtOrder(order as unknown as OrderBoughts))
          ) ?? [];

        const totalInvested = validOrders.reduce(
          (acc, order) => acc + (order.invested || 0),
          0
        );

        const totalNumOfCoins = validOrders.reduce(
          (acc, order) => acc + (order.invested || 0) / (order.buyPrice || 0),
          0
        );

        const currentValue = coin.currentPrice * totalNumOfCoins;

        console.log(`Coin: ${name}, Value: ${currentValue}`);
        return {
          name,
          value: currentValue,
          invested: totalInvested,
        };
      })
      .filter((item) => item.value > 0)
      .sort((a, b) => b.value - a.value);
  };

  const chartData = prepareChartData();
  const colors = generateColors(chartData.length);
  const decimals = notation === 'usd' ? 2 : 8;
  const currencySymbol = notation === 'usd' ? '$' : '₿';

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          labelLine={{
            stroke: '#E3CC8C',
            strokeWidth: 2,
            strokeDasharray: '2 2',
          }}
          label={(props) => {
            const { name, percent } = props;
            return `${name} ${(percent * 100).toFixed(0)}%`;
          }}
          outerRadius="58%"
          fill="#8884d8"
          dataKey="value"
          paddingAngle={5}
          strokeWidth={0}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index]} stroke="none" />
          ))}
        </Pie>
        <Tooltip
          formatter={(value: number, name: string, props: any) => [
            `invested ${currencySymbol}${props.payload.invested.toFixed(
              decimals
            )} -> ${currencySymbol}${value.toFixed(decimals)}`,
            name,
          ]}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default BalancePieChart;
