import * as ccxt from 'ccxt';
import { Order } from 'ccxt';

require('dotenv').config();

const exchangeId = 'binance';

const getBinanceKey = () => {
  try {
    return process.env.REACT_APP_BINANCE_API_KEY;
  } catch (error) {
    console.log(process.env.REACT_APP_BINANCE_API_KEY);
    return process.env.REACT_APP_BINANCE_API_KEY;
  }
};

const getBinanceSecret = () => {
  try {
    return process.env.REACT_APP_BINANCE_API_SECRET;
  } catch (error) {
    return process.env.REACT_APP_BINANCE_API_SECRET;
  }
};

const ExchangeClass = ccxt[exchangeId];
const binanceExchange = new ExchangeClass({
  apiKey: getBinanceKey(),
  secret: getBinanceSecret(),
  timeout: 600000,
  enableRateLimit: true,
  // verbose: true,
  options: {
    recvWindow: 60000, // 1 minute
    fetchMinOrderAmounts: false,
    adjustForTimeDifference: true,
  },
});

export const getBinanceBalance = async () => {
  try {
    // binanceExchange.proxy = 'https://cors-anywhere.herokuapp.com/';
    const balance = await binanceExchange.fetchBalance();
    const nonZeroBalanceCoins = Object.entries(balance).filter(
      (coin: [string, ccxt.Balance]) => coin[1].total && coin[1].total !== 0
    );

    return nonZeroBalanceCoins;
  } catch (error) {
    console.error(
      'REALORDER --> !!!!! error when fetching binance Balance !!!!!',
      error
    );
  }
  return undefined;
};

export const placeLimitBuyOrder = async (
  buyPercent: number,
  symbol: string,
  buyOrClosePrc: number,
  balToInvest: number
) => {
  let orderDetail = null;
  const buyPrice = buyOrClosePrc - (0.5 / 100) * buyOrClosePrc;
  const coinAmountToBuy = balToInvest! / buyPrice;

  try {
    console.info(
      `REALORDER --> placing Limit buy trade for ${symbol} for buy ${buyPercent}%`
    );
    orderDetail = await binanceExchange.createLimitOrder(
      symbol,
      'buy',
      coinAmountToBuy,
      buyPrice
    );
  } catch (error) {
    orderDetail = error;
  }
  return orderDetail;
};

export const placeLimitSellOrder = async (
  symbol: string,
  candleLow: number,
  coinAmountToSell?: number | null
) => {
  let orderDetail = null;
  const sellPrice = candleLow - (0.5 / 100) * candleLow;

  if (!coinAmountToSell) {
    throw new Error(
      `${symbol}-${candleLow} buyPercent has not coinAmountToSell provided`
    );
  }
  try {
    console.info(`REALORDER --> placing Limit Sell trade for ${symbol}`);
    orderDetail = await binanceExchange.createLimitOrder(
      symbol,
      'sell',
      0.995 * coinAmountToSell,
      sellPrice
    );
  } catch (error) {
    orderDetail = error;
  }
  return orderDetail;
};

export const placeMarketSellOrder = async (
  buyPercent: number,
  symbol: string,
  coinAmountToSell?: number | null
) => {
  let orderDetail = null;

  if (!coinAmountToSell) {
    throw new Error(
      `${symbol}-${buyPercent} buyPercent has not coinAmountToSell provided`
    );
  }
  try {
    console.info(
      `REALORDER --> placing market order for ${symbol} for buy ${buyPercent}%`
    );
    orderDetail = await binanceExchange.createMarketOrder(
      symbol,
      'sell',
      coinAmountToSell
    );

    return orderDetail;
  } catch (error) {
    orderDetail = error;
  }
  return orderDetail;
};

export const placeStoploss = async (
  symbol: string,
  candleClosPrice: number,
  balToInvest: number
) => {
  const coinAmountToBuy = balToInvest! / candleClosPrice;
  const stopPrice = (0.5 / 100) * candleClosPrice + candleClosPrice;

  const params = { stopPrice };
  let orderDetail = null;

  try {
    console.info(`REALORDER --> placing stoploss trade for ${symbol}`);

    orderDetail = await binanceExchange.createOrder(
      symbol,
      'STOP_LOSS_LIMIT',
      'buy',
      coinAmountToBuy,
      candleClosPrice,
      params
    );

    return orderDetail;
  } catch (error) {
    orderDetail = error;
  }

  return orderDetail;
};

export const cancelSpecificOrder = async (
  buyPercent: number,
  symbol: string,
  orderId?: string | null
) => {
  let orderDetail = null;
  if (!orderId) {
    throw new Error(
      `orderID for ${symbol}-${buyPercent} buPercent is not provided`
    );
  }
  try {
    console.info(
      `REALORDER --> cancelling order for ${symbol} with id ${orderId} for buy ${buyPercent}%`
    );
    orderDetail = await binanceExchange.cancelOrder(orderId, symbol);

    return orderDetail;
  } catch (error) {
    orderDetail = error;
  }
  return orderDetail;
};

export const checkOrderStatus = async (
  id: string,
  symbol: string,
  buyPercent: number,
  orderId?: Order['id'] | null
) => {
  if (!orderId) {
    throw new Error(
      `orderID for ${symbol}-${buyPercent} buPercent is not provided for ${id}`
    );
  }
  try {
    console.info(
      `REALORDER --> quering order detail for ${orderId} of ${symbol}  for buy ${buyPercent}%`
    );

    const orderStatus = await binanceExchange.fetchOrder(orderId, symbol);

    return orderStatus;
  } catch (error) {
    return error;
  }
};
