import { Order } from 'ccxt';

export type OrderInfo = {
  buyTime: number;
  sellTime: number;
  buyOrderInfo: Order | null;
  realBuyOrderStatus: string;
  sellOrderInfo: Order | null;
  realSellOrderStatus: string;
  buyPercent: number;
  buyColor: string;
  invested: number;
  buyPrice: number;
  stoploss: number;
  stoplossPrice: number;
  profitSecured: number;
  orderInfo: [boolean];
};

export const calcTimeTakenForCompleteTrade = (
  buyTime: number,
  sellTime?: number | null
) => {
  const potentialSellTime = sellTime ?? +new Date();
  const orderBuyTime =
    buyTime && buyTime.toString().length === 13 ? buyTime : buyTime * 1000;
  const orderSellTime =
    potentialSellTime.toString().length === 13
      ? potentialSellTime
      : potentialSellTime * 1000;
  const timeTaken = Math.abs(orderSellTime - orderBuyTime) / (1000 * 3600 * 24);

  return { buyTime: orderBuyTime, sellTime, timeTaken };
};

// This function converts a given time in seconds or milliseconds to milliseconds.
// If the input is already in milliseconds (13 digits), it returns the input as is.
// Otherwise, it multiplies the input by 1000 to convert seconds to milliseconds.
export const convertToMillis = (secOrMilli: number) => {
  const noOfDigits = secOrMilli.toString().length;
  const isMilli = Boolean(noOfDigits === 13);

  return isMilli ? secOrMilli : secOrMilli * 1000;
};

export const getTodayDateInYYYYMMDD = () => {
  const today: Date = new Date();

  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = +`0${dd}`;
  }

  if (mm < 10) {
    mm = +`0${mm}`;
  }

  const todayFormatted = `${yyyy}-${mm}-${dd}`;
  return todayFormatted;
};

export const getHumanReadableDate = (timeStamp: number) => {
  if (!timeStamp) return null;
  const milliSec = convertToMillis(timeStamp);
  const readableDate = new Date(milliSec).toDateString();
  const splittedDate = readableDate.split(' ');
  splittedDate.shift();

  return splittedDate.join(' ');
};

export const daysInSeconds = (days: number) => {
  const twoDayFromNowInMs = new Date().getTime() + 8.64e7 * days;
  const daysInSec = +(twoDayFromNowInMs / 1000).toFixed(0);
  return daysInSec;
};
