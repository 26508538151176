import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { createRoot } from 'react-dom/client';
import App from './App';
// import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error("Couldn't find the 'root' element");
}
