import React from 'react';
import { Form, Formik } from 'formik';
import {
  Button,
  Checkbox,
  Grid,
  Input,
  Label,
  Segment,
} from 'semantic-ui-react';
import AwsDatabase from '../../../database/Database';
import { TradeNotations } from '../../../types';

const AwsDB = new AwsDatabase();

type CoinFormProps = {
  coinID: string;
  notation: TradeNotations;
  higestPrice?: number;
  relativeHigh?: number;
  paperTrade?: boolean;
};

const changeValuesForCoin = (
  coinID: string,
  notation: TradeNotations,
  higestPrice: number,
  relativeHigh: number,
  paperTrade: boolean
) => {
  AwsDB.changeCoinPriceAndTrade(
    coinID,
    notation,
    higestPrice,
    relativeHigh,
    paperTrade
  );
};

export const AwsCoinForm: React.FC<CoinFormProps> = ({
  coinID,
  notation,
  higestPrice,
  relativeHigh,
  paperTrade,
}) => {
  if (!higestPrice || !relativeHigh || paperTrade === undefined) return null;

  return (
    <Formik
      initialValues={{
        coinID,
        notation,
        higestPrice,
        relativeHigh,
        paperTrade,
      }}
      onSubmit={async (values) => {
        // alert(JSON.stringify(values, null, 2));
        changeValuesForCoin(
          coinID,
          notation,
          values.higestPrice,
          values.relativeHigh,
          values.paperTrade
        );
      }}
      validate={(values) => {
        const errors: { higestPrice?: string; relativeHigh?: string } = {};
        if (!values.higestPrice) {
          errors.higestPrice = 'ATH price is required';
        }
        if (!values.relativeHigh) {
          errors.relativeHigh = 'High Price is required';
        }
        return errors;
      }}
    >
      {(props) => {
        const { values, errors, isSubmitting, handleChange } = props;
        return (
          <Grid.Row>
            <Grid.Column>
              <Form className="coinSettingForm">
                <Grid columns={4} stackable container>
                  <Grid.Column>
                    <Segment raised>
                      <Label as="div" color="green" ribbon>
                        ATH price
                      </Label>
                      <Input
                        transparent
                        id="higestPrice"
                        placeholder="add Higest Price"
                        type="number"
                        value={values.higestPrice}
                        onChange={handleChange}
                      />
                      {errors.higestPrice && (
                        <Label
                          basic
                          color="red"
                          pointing
                          className="floatRight"
                        >
                          {errors.higestPrice}
                        </Label>
                      )}
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment raised>
                      <Label as="div" color="red" ribbon>
                        HighPrice
                      </Label>
                      <Input
                        transparent
                        id="relativeHigh"
                        type="number"
                        placeholder="add Relative High Price"
                        value={values.relativeHigh}
                        onChange={handleChange}
                      />
                      {errors.relativeHigh && (
                        <Label
                          basic
                          color="red"
                          pointing
                          className="floatRight"
                        >
                          {errors.relativeHigh}
                        </Label>
                      )}
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment raised>
                      <Label as="div" color="orange" ribbon>
                        Paper Trade ?
                      </Label>
                      {/* <Field type="checkbox" name="paperTrade" /> */}
                      {/* cannot make below work! */}
                      <Checkbox
                        toggle
                        className="vmiddle"
                        id="paperTrade"
                        name="paperTrade"
                        defaultChecked={values.paperTrade}
                        onChange={handleChange}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment basic>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        color="green"
                        size="medium"
                      >
                        Save New Values
                      </Button>
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Form>
            </Grid.Column>
          </Grid.Row>
        );
      }}
    </Formik>
  );
};
