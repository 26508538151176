import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type UseAuthProps = {
  useAuto?: boolean;
};

const useAuth = ({ useAuto = false }: UseAuthProps) => {
  const [user, setUser] = useState<any>(undefined);
  const isLoggedIn = user !== null && user !== undefined;
  const navigate = useNavigate();
  const location = useLocation();

  const checkUser = async (): Promise<void> => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser?.attributes.email === 'ronitakon27@gmail.com') {
        setUser(currentUser);
        if (location.pathname === '/login' || location.pathname === '/') {
          navigate('/dashboard');
        }
      } else {
        console.error('User is not authorized');
        alert('You are not authorized to view this page');
        await Auth.signOut();
        setUser(null);
        navigate('/login');
      }
    } catch (error) {
      console.error(error);
      console.log('Not signed in');
      setUser(null);
      const publicPaths = ['/login', '/'];
      if (!publicPaths.includes(location.pathname)) {
        navigate('/login');
      }
    }
  };

  const signIn = async (): Promise<void> => {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  useEffect(() => {
    checkUser();
    console.log('Checking user');
  }, [location.pathname]);

  useEffect(() => {
    if (user !== undefined && user?.username) {
      console.log('User is signed in');
    }
    if (user === null && useAuto && location.pathname !== '/') {
      console.log('User is not signed in... need to sign in');
      signIn();
    }
  }, [user, location.pathname]);

  return { user, isLoggedIn, signIn };
};

export default useAuth;
