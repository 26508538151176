/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://iz3umprqwbgdvo6oxiwj3hmeki.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fxog2fr3gveddnpmhg6zx4bury",
    "aws_cognito_identity_pool_id": "eu-central-1:1e68af29-7f13-4b05-96b8-73da9b7a4ea5",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_5u0FHw8oG",
    "aws_user_pools_web_client_id": "7dph7ra2buqa07jtun3mh8kl15",
    "oauth": {
        "domain": "get-rich-slow-auth-getrich.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://getrichslowy.com/,https://www.getrichslowy.com/,http://localhost:3000/",
        "redirectSignOut": "https://getrichslowy.com/,https://www.getrichslowy.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "getrich-slow-hosting-getrich",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://dc1wbyppv5pa.cloudfront.net"
};


export default awsmobile;
