import React from 'react';
import { Button, Grid, Icon, Label, Step } from 'semantic-ui-react';
import useAuth from '../hooks/useAuth';
import '../css/sale-page.css';
import { MainHeader } from '../layout/Header';
import PortfolioChart from '../layout/PortfolioChart';
import { revertCoinId } from '../../utils/utils';
import { ReactTable } from '../SubComponents/tables/ReactTable';
import { convertToTableFormat } from '../SubComponents/tables/CoinOrdersTable';
import { Percentage } from '../SubComponents/Percentage';
import { getSalesPageData } from '../../api/apiCalls';

export const SalesPage: React.FC = () => {
  useAuth({ useAuto: false });
  const [salesPageData, setSalesPageData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchSalesPageData = async () => {
      try {
        const response = await getSalesPageData();
        setSalesPageData(response);
      } catch (error) {
        console.error('Error fetching sales page data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesPageData();
  }, []);

  const portfolio = salesPageData?.portfolio;
  const recentTrades = salesPageData?.recentTrades;
  const tradingPairs = salesPageData?.tradingPairs;

  const tableCompatableData = React.useMemo(
    () => (recentTrades ? convertToTableFormat(recentTrades) : null),
    [recentTrades]
  );

  if (loading) {
    return null;
  }

  return (
    <div className="sales-page">
      {/* <MainHeader /> */}

      <section
        className="fullscreen-bg"
        style={{
          backgroundImage: 'url(../../sales-bg.png)',
        }}
      >
        <Grid container>
          <Grid.Row>
            <Grid.Column mobile={16} computer={8}>
              <header className="cta-box">
                <h1>
                  <div>Unlock Your</div> Financial Freedom
                </h1>
                <p>
                  Begin your journey towards financial freedom with our expertly
                  designed trading bot. Our mission is to empower you to achieve
                  financial independence and secure a prosperous future.
                </p>
                <Button
                  size="big"
                  color="google plus"
                  className="cta-button"
                  type="button"
                  onClick={() => {
                    window.location.href = `https://t.me/getrichslowy`;
                  }}
                >
                  <Icon name="telegram" />
                  Join Telegram to receive Trade Signals
                </Button>
              </header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section>
      <Grid container>
        <Grid.Column>
          <main>
            <section className="how-it-works trades">
              <h2>Five recent ongoing and completed trades </h2>
              <Grid>
                {tableCompatableData && (
                  <ReactTable
                    columns={[
                      {
                        Header: 'Coin',
                        accessor: 'id',
                        width: 120,
                      },
                      {
                        Header: 'Notation',
                        accessor: 'notation',
                        width: 80,
                      },
                      {
                        Header: 'Buy price',
                        accessor: 'buyPrice',
                      },
                      {
                        Header: 'Buy Date',
                        accessor: 'buyTime',
                      },
                      {
                        Header: 'Sell / Stoploss price',
                        accessor: 'stoplossInfo',
                      },
                      {
                        Header: 'Sell Date',
                        accessor: 'sellTime',
                      },
                      {
                        Header: 'profit Secured',
                        accessor: 'profitSecured',
                        width: 120,
                      },
                      {
                        Header: 'Time Taken',
                        accessor: 'timeTaken',
                        width: 100,
                      },
                    ]}
                    data={tableCompatableData}
                    sortAccessor="sellTime"
                    descending
                    compact
                    hideColumnsOnMobile={[
                      'notation',
                      'buyTime',
                      'stoplossInfo',
                      'sellTime',
                    ]}
                  />
                )}
              </Grid>
            </section>

            <section className="how-it-works nodisplay">
              <h2>Ok... what is the secret sauce ? </h2>
              <p>
                the essence of the strategy for the bot is quite simple. We buy
                low and sell high. we only buy when there is panic in the
                market. This we do by looking at relative high prices in past
                months. The bot keeps track of average drawdowns and average
                high prices for each coin. When the price is below the average
                drawdown we set alerts as the price now starts to get
                interesting for us.
              </p>
              <p>
                The analogy of the style of trading is like fishing. We are not
                a day trader. We are a fisherman. We cast our line and wait for
                the fish to bite. We are not chasing the fish. We are waiting
                for the fish to come to us. The bot trades on daily chart so we
                are not day trading. The bot goes for the larger trends.
              </p>
              <p>
                for risk management we have a trailing stoploss in place both
                when buying and sell. The bot does not buy all at once. It buys
                in layers so the lower the price goes the more it buys. This
                helps to reduce the average buy price.
              </p>
              <p>
                the bot trades top coins that are vetted and has a long history
                so they are fundamentally sound investments even in the unlikey
                event that we end up bag holding. And because we are trading 30
                + coins we are diversified. Plus every trades, even with small
                profits, adds up and compounds quickly over time.
              </p>
              <p>
                even though we trade on high timeframes, the bot when a trade is
                found, reacts in realtime to capture the volatility
              </p>
            </section>

            <section className="trading-pairs">
              <h2>Pairs currently being traded by the bot</h2>
              <Grid>
                <Grid.Column>
                  <ul>
                    {tradingPairs &&
                      tradingPairs
                        .sort((a: { name: string }, b: { name: any }) =>
                          a.name.localeCompare(b.name)
                        )
                        .map((coin: { id: string; name: string }) => (
                          <li key={coin.id}>
                            <img
                              src={`https://cryptologos.cc/logos/${revertCoinId(
                                coin.id
                              )}-logo.svg?v=010`}
                              alt=""
                            />
                            <Label>{coin.name}</Label>
                          </li>
                        ))}
                  </ul>
                </Grid.Column>
              </Grid>
            </section>

            <section className="testimonials nodisplay">
              <h2>What Our Users Say</h2>
              <blockquote>
                <p>
                  &ldquo;Ive achieved financial freedom thanks to [Your Bot
                  Name]. Its reliable, easy to use, and consistently
                  profitable.&rdquo; - Jane D.
                </p>
              </blockquote>
              <blockquote>
                <p>
                  &ldquo;The best decision Ive made for my investments. The bot
                  handles everything, and I can track my progress easily.&rdquo;
                  - John S.
                </p>
              </blockquote>
            </section>

            <section className="faq nodisplay">
              <h2>Frequently Asked Questions</h2>
              <div className="faq-item">
                <h3>How does the trading bot work?</h3>
                <p>
                  Our bot uses advanced algorithms to analyze market data and
                  execute trades automatically. It removes the emotional aspect
                  of trading, ensuring consistent and rational decision-making.
                </p>
              </div>
              <div className="faq-item">
                <h3>Is my money safe?</h3>
                <p>
                  Absolutely. We employ industry-standard security measures to
                  ensure your funds and personal data are protected at all
                  times.
                </p>
              </div>
              <div className="faq-item">
                <h3>Can I try the bot before committing?</h3>
                <p>
                  Yes, we offer a free trial period for new users. Sign up now
                  to experience the benefits without any risk.
                </p>
              </div>
              <div className="faq-item">
                <h3>What kind of customer support do you offer?</h3>
                <p>
                  We provide 24/7 customer support through various channels,
                  including live chat, email, and phone. Our support team is
                  always ready to assist you with any questions or issues.
                </p>
              </div>
            </section>
          </main>
        </Grid.Column>
      </Grid>
      <section className="past-performance PortfolioChart nodisplay">
        {/* <h2>Let the numbers speak for itself</h2> */}
        <h2>Get-rich-slow&apos;s realtime performance against the market</h2>

        {portfolio && <PortfolioChart data={portfolio} width="80" />}
      </section>
      <section className="features-benefits">
        <h2>Features and Benefits</h2>
        <Grid container>
          <Grid.Row
            columns={2}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <img src="./getRichSlow-trans.png" alt="" />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <ul>
                <li>
                  <h3>Simple yet Smart Strategies</h3>
                  Our bot employs straightforward yet effective strategies: buy
                  low and sell high. We capitalize on market panic by
                  identifying relative high prices from previous months. The bot
                  tracks average drawdowns and high prices for each coin,
                  layering buys when the price drops below the average drawdown,
                  signaling an optimal buying opportunity.
                </li>
                <li>
                  <h3>Automation</h3>
                  Avoid the pitfalls of day trading—95% of day traders lose
                  money! Set your preferences once and let our bot handle the
                  rest. Our automated system tirelessly works to grow your
                  portfolio. Even though we trade on high timeframes, the bot
                  reacts in real-time to market volatility, ensuring you capture
                  the best opportunities without constant monitoring.
                </li>
                <li>
                  <h3>Risk management</h3>
                  Effective risk management is key to our strategy. Our bot
                  employs trailing stop-loss mechanisms for both buying and
                  selling. Instead of buying all at once, the bot purchases in
                  layers, increasing its position as prices drop to reduce the
                  average buy price and manage risk effectively.
                </li>
                <li>
                  <h3>Diversification</h3>
                  Our bot trades top-tier coins with long, proven histories,
                  ensuring they are fundamentally sound investments. By trading
                  over 30 different coins, we achieve robust diversification.
                  Each trade, even with small profits, compounds over time,
                  contributing to steady portfolio growth and minimizing the
                  risk of bag holding.
                </li>
              </ul>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section>
      <section className="past-performance backtest">
        <div>
          <h2>Our bot has consistently outperformed the market</h2>

          {/* <h4>compunding magic at its finest!</h4> */}
          <Grid container className="past-perf-numbers">
            <Grid.Column>
              <Step.Group fluid className="">
                <Step
                  icon="calendar alternate outline"
                  title={<h3>Jan 01 2018 - Dec 27 2018</h3>}
                  description="backtesting period"
                />
                <Step
                  icon="dollar"
                  title={<h2 className="noMargin">100,000</h2>}
                  description="initial Capital"
                />
                <Step
                  icon="shopping basket"
                  description={
                    <div>
                      <h1>
                        <Percentage percentage={871.5}>189.23%</Percentage>
                        <hr style={{ border: '1px solid #333' }} />
                      </h1>
                      <div style={{ marginBottom: '4px' }}>
                        <b>OTHERS</b> profit/loss
                      </div>
                      <Percentage percentage={-87.2}>-87.20%</Percentage>
                    </div>
                  }
                  active
                  className="center"
                />
                <Step
                  icon="dollar arrow up"
                  title={<h2 className="noMargin">189,230</h2>}
                  description={
                    <>
                      <div>
                        balance increase <em>without capital</em>
                      </div>
                    </>
                  }
                />
              </Step.Group>
            </Grid.Column>
          </Grid>
          <Grid container className="past-perf-numbers">
            <Grid.Column>
              <Step.Group fluid>
                <Step
                  icon="calendar alternate outline"
                  title={<h3>May 01 2021 - Nov 09 2023</h3>}
                  description="backtesting period"
                />
                <Step
                  icon="dollar"
                  title={<h2 className="noMargin">200,000</h2>}
                  description="initial Capital"
                />

                <Step
                  icon="chart line"
                  description={
                    <div>
                      <h1>
                        <Percentage percentage={424.24}>424.24%</Percentage>
                        <hr style={{ border: '1px solid #333' }} />
                      </h1>
                      <div style={{ marginBottom: '4px' }}>
                        <b>OTHERS</b> profit/loss
                      </div>
                      <Percentage percentage={-52.93}>-52.93%</Percentage>
                    </div>
                  }
                  active
                  className="center"
                />
                <Step
                  icon="dollar arrow up"
                  title={<h2 className="noMargin">1,743,000</h2>}
                  description={
                    <>
                      <div>
                        balance increase <em>without capital</em>
                      </div>
                    </>
                  }
                />
              </Step.Group>
            </Grid.Column>
          </Grid>
        </div>
      </section>
      <section className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <Grid container>
          <Grid.Column mobile={16} tablet={5} computer={5}>
            <img src="./logos-trading.png" alt="Intelligent Trading logo" />
            <h3>Long-Term Strategy for Wealth Building</h3>
            <p>
              We understand that wealth-building is a marathon, not a sprint.
              Our bot trades on a daily chart, focusing on larger trends and
              avoiding the stress of daily market fluctuations. Think of our
              approach as fishing—we cast our line and patiently wait for the
              right opportunities.
            </p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={5} computer={5}>
            <img
              src="./logos-freedom.png"
              alt="Embrace Financial Freedom logo"
            />
            <h3>Achieve Financial Freedom</h3>
            <p>
              At Get Rich Slow, we empower you to achieve true financial
              freedom. Our sophisticated algorithms and proven strategies are
              designed to outperform the crypto market in all conditions,
              especially in a bear market.
            </p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={5} computer={5}>
            <img src="./logos-slow.png" alt="Get Rich Slow logo" />
            <h3>Consistent and Reliable Growth</h3>
            <p>
              Experience consistent, reliable growth with advanced trading
              strategies tailored to maximize profits and minimize risks. Trust
              in our system to handle the complexities of the market, so you can
              enjoy the freedom to live, love, and laugh without financial
              constraints.
            </p>
          </Grid.Column>
        </Grid>
      </section>

      <section
        className="start-today"
        style={{ backgroundImage: 'url(../../bg-turtle-side.png)' }}
        //   style={{ backgroundImage: 'url(../../bg-lines.png)' }}
      >
        <div>
          <h2>
            <div>Join</div> Telegram{' '}
            <span>
              to <br />
              receive
            </span>
            <br />
            Trade signals
          </h2>
          {/* <p>
              Join the ranks of satisfied users who have taken control of their
              financial future. It's time to make your money work for you.
            </p>
            <p>
              Don't just dream of financial freedom—achieve it. Sign up now and
              take the first step towards a wealthier, happier life.
            </p> */}
          <Button
            size="massive"
            color="google plus"
            className="cta-button"
            type="button"
            onClick={() => {
              window.location.href = `https://t.me/getrichslowy`;
            }}
          >
            <Icon name="telegram" />
            Join Now for Free
          </Button>
          <Button
            size="massive"
            color="blue"
            className="cta-button"
            type="button"
            onClick={() => {
              window.location.href = `/login`;
            }}
          >
            <Icon name="sign-in" />
            Login
          </Button>
        </div>
      </section>
    </div>
  );
};
