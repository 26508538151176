import React from 'react';
import { Container, Grid, Header, Menu, Divider } from 'semantic-ui-react';
import { MainHeader } from '../layout/Header';
import { SettingsForm } from '../SubComponents/forms/SettingForm';
import { CoinManagementForm } from '../SubComponents/forms/CoinManagementForm';
import { Settings, GlobalSettings } from '../../types';
import AwsDatabase from '../../database/Database';

const AwsDB = new AwsDatabase();

const SettingsPage: React.FC = () => {
  const [globalSettings, setGlobalSettings] =
    React.useState<Settings['globalSettings']>();
  const [activeMenu, setActiveMenu] = React.useState('trading');

  React.useEffect(() => {
    const settings = AwsDB.getGlobalSettings();
    settings.then((data: GlobalSettings) => {
      if (data !== globalSettings) {
        setGlobalSettings(data);
      }
    });
  }, []);

  return (
    <>
      <MainHeader />
      <Container>
        <Header
          style={{ margin: '20px 0' }}
          size="large"
          textAlign="center"
          as="h1"
          content="Settings"
          subheader="Manage your trading preferences and account settings"
        />
        <Divider />

        <Grid stackable>
          <Grid.Column width={4}>
            <Menu vertical fluid>
              <Menu.Item
                active={activeMenu === 'trading'}
                onClick={() => setActiveMenu('trading')}
              >
                <Header as="h4">Trading Settings</Header>
              </Menu.Item>
              <Menu.Item
                active={activeMenu === 'coins'}
                onClick={() => setActiveMenu('coins')}
              >
                <Header as="h4">Coin Management</Header>
              </Menu.Item>
            </Menu>
          </Grid.Column>

          <Grid.Column width={12}>
            {globalSettings && activeMenu === 'trading' && (
              <SettingsForm settings={globalSettings} />
            )}
            {activeMenu === 'coins' && <CoinManagementForm />}
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
};

export default SettingsPage;
