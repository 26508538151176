// create a react functional component that uses react table
import React, { memo, useEffect, useState } from 'react';
import {
  Cell,
  Column,
  useBlockLayout,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import { Button, Icon, Input, Label, Table } from 'semantic-ui-react';
import { getHumanReadableDate } from '../../../utils/calculations/timeCalculation';
import {
  getAlertTriggeredDiv,
  getButtonsBasedOnOrders,
  getPercFromAllTimeHighColumn,
  getPercFromFourMonthHighColumn,
  getTrProps,
  getTradePairColumn,
  getTradesOnColumn,
  omitKey,
} from './coinsTableParts';
import { Price, Prices } from '../Prices';
import AwsDatabase from '../../../database/Database';
import { CoinOrdersTable } from '../../../types';
import { OrderBoughts } from '../../../API';
import { useHideColumnsOnMobile } from '../../hooks/setHiddenColumns';
import { formatNumberDE, revertCoinId } from '../../../utils/utils';
const AwsDB = new AwsDatabase();

export type ReactTableProps = {
  columns: Column[];
  data: object[];
  sortAccessor: string;
  hideColumnsOnMobile?: Array<string>;
  currentPrice?: number;
  istradePairBtc?: boolean;
  descending?: boolean;
  compact?: boolean;
};

const renderTableData = (
  cell: Cell,
  istradePairBtc: boolean,
  currentPrice?: number
) => {
  const notation = istradePairBtc ? 'btc' : 'usd';

  switch (cell.column.id) {
    case 'id':
      return (
        <>
          <img
            className="coin-icon"
            src={`https://cryptologos.cc/logos/${revertCoinId(
              (cell.row.original as any).orderData.id
            )}-logo.svg?v=010`}
            alt="coin logo"
          />
          <Label
            style={{
              width: '50px',
              textAlign: 'center',
              backgroundColor: `${
                (cell.row.original as any).orderData.buyColor
              }`,
              color: 'white',
            }}
          >
            {(cell.row.original as any).orderData.id
              .split('-')[0]
              .toUpperCase()}
          </Label>
        </>
      );
    case 'notation':
      return (
        <Label>
          {(cell.row.original as any).orderData.notation.toUpperCase()}
        </Label>
      );
    case 'percAndColor':
      return (
        <p
          style={{
            display: 'inline-block',
            width: '100%',
            backgroundColor: `${cell.value[1]}`,
            color: '#fff',
            textAlign: 'center',
          }}
        >
          {cell.value[0]}
        </p>
      );
    case 'invested': {
      if (!cell.value) {
        return null;
      }

      const fixedVal =
        cell.value < 100 ? cell.value.toFixed(5) : cell.value.toFixed(0);

      return <>{formatNumberDE(fixedVal)}</>;
    }
    case 'buyPrice':
      return Number(cell.value).toFixed(6);
    case 'profitSecured':
      return cell.value ? (
        <div
          className="profitSecured"
          style={{ color: cell.value < 0 ? 'red' : 'initial' }}
        >
          {cell.value.toFixed(1)}%
        </div>
      ) : null;
    case 'timeTaken':
      return cell.value ? <>{`${cell.value.toFixed(1)} days`}</> : null;
    case 'orderInfo':
      return cell.value[0] ? (
        <Icon
          name="certificate"
          style={{
            color: 'orange',
          }}
        />
      ) : null;
    case 'buyTime':
      return getHumanReadableDate(cell.value); // need to make it sorted
    case 'sellTime':
      return getHumanReadableDate(cell.value);
    case 'stoplossInfo':
      return cell.value ? cell.value[1]?.toFixed(6) : null;
    case 'rank':
      return <p style={{ textAlign: 'center', width: '100%' }}>{cell.value}</p>;
    case 'coinDetails':
      return cell.value ? (
        <p>{getTradePairColumn(cell.value, istradePairBtc)}</p>
      ) : null;
    case 'currentPrice':
      return (
        <Prices
          satoshiPrice={
            istradePairBtc
              ? (cell.row.original as any).currentPrice
              : (cell.row.original as any).currentAlternatePrice
          }
          usdPrice={
            istradePairBtc
              ? (cell.row.original as any).currentAlternatePrice
              : (cell.row.original as any).currentPrice
          }
        />
      );
    case 'fromFourMonthATH':
      return getPercFromFourMonthHighColumn(cell.value);
    case 'relativeHigh':
      return <Price istradePairBtc={istradePairBtc} cell={cell} />;
    case 'fromATH':
      return getPercFromAllTimeHighColumn(cell.value);
    case 'higestPrice':
      return <Price istradePairBtc={istradePairBtc} cell={cell} />;
    case 'alerts':
      return getAlertTriggeredDiv(cell.value);
    case 'tradeDetails':
      return getTradesOnColumn(cell.value);
    case 'coinActions':
      return currentPrice
        ? getButtonsBasedOnOrders(
            cell.row.original as CoinOrdersTable,
            currentPrice
          )
        : null;
    case 'action': {
      const details = (cell.row.original as { coinDetails: Array<string> })
        .coinDetails;
      const coinID = details[0];
      const dontTrade = details[2];

      return (
        <Button
          compact
          size="mini"
          type="button"
          className="btn btn-error"
          onClick={() => {
            AwsDB.makeTheCoinNonTradeable(coinID, dontTrade, notation);
            // activateDontTradeForCoin();
          }}
        >
          {!dontTrade ? 'Dont trade' : 'Trade again'}
        </Button>
      );
    }
    default:
      return cell.value;
  }
};

const arePropsEqual = (
  prevProps: ReactTableProps,
  nextProps: ReactTableProps
) => {
  const prevData = prevProps.data;
  const nextData = nextProps.data;

  const weAreInDashboard = Boolean(
    prevData.length && (prevData[0] as any).rank
  );
  const dataIsDifferent = prevData.length !== nextData.length;

  if (dataIsDifferent) {
    return false;
  }

  if (weAreInDashboard) {
    return false;
  }

  for (let i = 0; i < prevData.length; i++) {
    const prevProfit = (prevData[i] as OrderBoughts).profitSecured;
    const nextProfit = (nextData[i] as OrderBoughts).profitSecured;

    if (prevProfit !== nextProfit) {
      return false;
    }
  }

  return true;
};

export const ReactTable: React.FC<ReactTableProps> = memo(
  ({
    columns,
    data,
    sortAccessor,
    currentPrice,
    hideColumnsOnMobile,
    descending = false,
    istradePairBtc = true,
    compact = false,
  }) => {
    const hiddenColumns = useHideColumnsOnMobile(hideColumnsOnMobile ?? []);

    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      rows, // rows for the table based on the data passed
      prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
      // @ts-ignore
      setGlobalFilter,
      state,
      setHiddenColumns,
    } = useTable(
      {
        columns,
        data,
        initialState: {
          // @ts-ignore
          sortBy: [{ id: sortAccessor, desc: descending }],
          hiddenColumns,
        },
      },
      useBlockLayout,
      useGlobalFilter,
      useSortBy
    );

    useEffect(() => {
      setHiddenColumns(hiddenColumns);
    }, [hiddenColumns, setHiddenColumns]);
    // @ts-ignore
    const { globalFilter } = state;

    return (
      <>
        <>
          {!compact && (
            <Input
              icon="search"
              placeholder="Search..."
              value={globalFilter || ''}
              onChange={(e, { value }) => setGlobalFilter(value)}
              style={{
                margin: '0 auto',
                maxWidth: '1380px',
                display: 'flex',
              }}
            />
          )}
          <Table {...getTableProps()} collapsing sortable celled fixed>
            <Table.Header
            // style={{ padding: '0.3em' }}
            >
              {headerGroups.map((headerGroup) => (
                <Table.Row
                  {...omitKey(headerGroup.getHeaderGroupProps())}
                  key={headerGroup.getHeaderGroupProps().key}
                  // style={{ padding: '0.3em' }}
                >
                  {headerGroup.headers.map((column: any) => (
                    <Table.HeaderCell
                      {...omitKey(
                        column.getHeaderProps(column.getSortByToggleProps())
                      )}
                      key={column.id}
                      style={{
                        ...column.getHeaderProps().style,
                        padding: '2px 4px',
                        borderLeft: 'none',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {column.render('Header')}

                      <Icon
                        color={column.isSorted ? 'black' : 'grey'}
                        corner
                        size={column.isSorted ? 'small' : 'tiny'}
                        name={
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'sort down'
                              : 'sort up'
                            : 'sort'
                        }
                      />
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body {...getTableBodyProps()} style={{ padding: '0.3em' }}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Table.Row
                    {...omitKey(row.getRowProps())}
                    key={row.getRowProps().key}
                    style={{
                      ...row.getRowProps().style,
                      ...getTrProps('', row),
                    }}
                  >
                    {row.cells.map((cell) => (
                      <Table.Cell
                        {...omitKey(cell.getCellProps())}
                        key={cell.getCellProps().key}
                        id={cell.column.id}
                        style={{
                          ...cell.getCellProps().style,
                          padding: '2px 4px',
                          borderLeft: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          // todo make React happy by caluclating SemanticWIDTHS from  cell.getCellProps().style.width
                        }}
                      >
                        {renderTableData(
                          cell as unknown as Cell,
                          istradePairBtc,
                          currentPrice
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      </>
    );
  },
  arePropsEqual
);
