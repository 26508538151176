import React from 'react';
import { Card, Divider } from 'semantic-ui-react';
import '../css/style.css';

type DashboardCardsProps = {
  title?: React.ReactElement;
  content: React.ReactElement;
};

const DashboardCards: React.FC<DashboardCardsProps> = ({ title, content }) => (
  <Card fluid className="dashboard-card" style={{ boxShadow: 'none' }}>
    <Card.Content>
      {title && (
        <>
          <Card.Header>{title}</Card.Header>
          <Divider section className="hide-on-mobile" />
        </>
      )}
      <Card.Description>{content}</Card.Description>
    </Card.Content>
  </Card>
);

export default DashboardCards;
