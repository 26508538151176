import React from 'react';
import { Cell } from 'react-table';
import { Icon } from 'semantic-ui-react';

export type PricesProps = { satoshiPrice: number; usdPrice: number };

export const UsdPrice: React.FC<{
  usdPrice: number;
}> = ({ usdPrice }) => (
  <>
    <Icon name="dollar sign" color="grey" size="small" />
    {usdPrice}
  </>
);

export const SatoshiPrice: React.FC<{ satoshiPrice: number }> = ({
  satoshiPrice,
}) => (
  <>
    <Icon name="btc" color="grey" size="small" />
    <i style={{ color: 'grey' }}>{satoshiPrice}</i>
  </>
);

export const Prices: React.FC<PricesProps> = ({ satoshiPrice, usdPrice }) => (
  <span style={{ display: 'inline-block', width: '100%' }}>
    <UsdPrice usdPrice={usdPrice} />
    <span className="floatRight">
      <SatoshiPrice satoshiPrice={satoshiPrice} />
    </span>
  </span>
);

export const Price: React.FC<{
  istradePairBtc: boolean;
  cell: Cell<{}, any>;
}> = ({ istradePairBtc, cell }) =>
  istradePairBtc ? (
    <SatoshiPrice satoshiPrice={cell.value} />
  ) : (
    <UsdPrice usdPrice={cell.value} />
  );
