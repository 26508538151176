import React from 'react';
import { Icon, Label, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

export type LargeTextProps = {
  vol?: string | number;
  icon?: SemanticICONS;
  iconColor?: SemanticCOLORS;
  children?: React.ReactNode;
};
export const LargeText: React.FC<LargeTextProps> = ({
  vol,
  icon,
  iconColor,
  children,
}) => (
  <div>
    <Label as="div" size="huge">
      {icon && <Icon name={icon} color={iconColor ?? 'grey'} />}
      {vol} {children}
    </Label>
  </div>
);

export default LargeText;
