import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import AppRoutes from './AppRoutes';
import awsExports from './aws-exports';
import { enable, setFetchMethod } from 'darkreader';

const isDev = process.env.NODE_ENV !== 'production';

const updateAmplifyExports = () => {
  // Assuming you have two redirect URIs, and the first is for localhost and second is for production
  const [
    productionRedirectSignIn,
    productionwwwRedirectSignIn,
    localRedirectSignIn,
  ] = awsExports.oauth.redirectSignIn.split(',');

  const [
    productionRedirectSignOut,
    productionwwwRedirectSignOut,
    localRedirectSignOut,
  ] = awsExports.oauth.redirectSignOut.split(',');

  const updatedawsExports = {
    ...awsExports,
    oauth: {
      ...awsExports.oauth,
      redirectSignIn: isDev ? localRedirectSignIn : productionRedirectSignIn,
      redirectSignOut: isDev ? localRedirectSignOut : productionRedirectSignOut,
    },
  };

  Amplify.configure(updatedawsExports);
};

updateAmplifyExports();

setFetchMethod(window.fetch);
enable({
  brightness: 100,
  contrast: 96,
  sepia: 0,
});

const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;
