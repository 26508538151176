/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateSettingsInput = {
  id?: string | null,
  noOflayersToPlace: number,
  noOfLayersToSkip: number,
  fatFingerDropPerc: number,
  skipAlltrades: boolean,
  skipAllBtctrades: boolean,
  skipAllUsdtrades: boolean,
  collectFreeCoins: boolean,
  lastAlertUpdatedDate: number,
  baseType: string,
  marketCycle?: MarketCycle | null,
};

export enum MarketCycle {
  bull = "bull",
  bear = "bear",
  violent = "violent",
  accumulation = "accumulation",
}


export type ModelSettingsConditionInput = {
  noOflayersToPlace?: ModelFloatInput | null,
  noOfLayersToSkip?: ModelFloatInput | null,
  fatFingerDropPerc?: ModelFloatInput | null,
  skipAlltrades?: ModelBooleanInput | null,
  skipAllBtctrades?: ModelBooleanInput | null,
  skipAllUsdtrades?: ModelBooleanInput | null,
  collectFreeCoins?: ModelBooleanInput | null,
  lastAlertUpdatedDate?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  marketCycle?: ModelMarketCycleInput | null,
  and?: Array< ModelSettingsConditionInput | null > | null,
  or?: Array< ModelSettingsConditionInput | null > | null,
  not?: ModelSettingsConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelMarketCycleInput = {
  eq?: MarketCycle | null,
  ne?: MarketCycle | null,
};

export type Settings = {
  __typename: "Settings",
  id: string,
  noOflayersToPlace: number,
  noOfLayersToSkip: number,
  fatFingerDropPerc: number,
  skipAlltrades: boolean,
  skipAllBtctrades: boolean,
  skipAllUsdtrades: boolean,
  collectFreeCoins: boolean,
  lastAlertUpdatedDate: number,
  baseType: string,
  marketCycle?: MarketCycle | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSettingsInput = {
  id: string,
  noOflayersToPlace?: number | null,
  noOfLayersToSkip?: number | null,
  fatFingerDropPerc?: number | null,
  skipAlltrades?: boolean | null,
  skipAllBtctrades?: boolean | null,
  skipAllUsdtrades?: boolean | null,
  collectFreeCoins?: boolean | null,
  lastAlertUpdatedDate?: number | null,
  baseType?: string | null,
  marketCycle?: MarketCycle | null,
};

export type DeleteSettingsInput = {
  id: string,
};

export type CreateBalancesInput = {
  id?: string | null,
  btc: BalanceInput,
  usd: BalanceInput,
  lastUpdated: number,
  baseType: string,
};

export type BalanceInput = {
  free: number,
  total: number,
  real: number,
};

export type ModelBalancesConditionInput = {
  lastUpdated?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  and?: Array< ModelBalancesConditionInput | null > | null,
  or?: Array< ModelBalancesConditionInput | null > | null,
  not?: ModelBalancesConditionInput | null,
};

export type Balances = {
  __typename: "Balances",
  id: string,
  btc: Balance,
  usd: Balance,
  lastUpdated: number,
  baseType: string,
  createdAt: string,
  updatedAt: string,
};

export type Balance = {
  __typename: "Balance",
  free: number,
  total: number,
  real: number,
};

export type UpdateBalancesInput = {
  id: string,
  btc?: BalanceInput | null,
  usd?: BalanceInput | null,
  lastUpdated?: number | null,
  baseType?: string | null,
};

export type DeleteBalancesInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  coinID?: string | null,
  notation: TradeNotations,
  type: NotificationType,
  message: string,
  seen: boolean,
  date: number,
  baseType: string,
  delAfterDealt?: number | null,
};

export enum TradeNotations {
  usd = "usd",
  btc = "btc",
}


export enum NotificationType {
  error = "error",
  success = "success",
  warning = "warning",
}


export type ModelNotificationConditionInput = {
  coinID?: ModelStringInput | null,
  notation?: ModelTradeNotationsInput | null,
  type?: ModelNotificationTypeInput | null,
  message?: ModelStringInput | null,
  seen?: ModelBooleanInput | null,
  date?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  delAfterDealt?: ModelIntInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelTradeNotationsInput = {
  eq?: TradeNotations | null,
  ne?: TradeNotations | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  coinID?: string | null,
  notation: TradeNotations,
  type: NotificationType,
  message: string,
  seen: boolean,
  date: number,
  baseType: string,
  delAfterDealt?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNotificationInput = {
  id: string,
  coinID?: string | null,
  notation?: TradeNotations | null,
  type?: NotificationType | null,
  message?: string | null,
  seen?: boolean | null,
  date?: number | null,
  baseType?: string | null,
  delAfterDealt?: number | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateAlertsInput = {
  id?: string | null,
  buyType: BuyType,
  buyOrBoughtId: string,
  status: AlertStatus,
  delAfterDealt?: number | null,
  recheckCount?: number | null,
  alertsBuyOrderId?: string | null,
  alertsBoughtOrderId?: string | null,
};

export enum BuyType {
  buy = "buy",
  sell = "sell",
}


export enum AlertStatus {
  new = "new",
  complete = "complete",
  recheck = "recheck",
}


export type ModelAlertsConditionInput = {
  buyType?: ModelBuyTypeInput | null,
  buyOrBoughtId?: ModelStringInput | null,
  status?: ModelAlertStatusInput | null,
  delAfterDealt?: ModelIntInput | null,
  recheckCount?: ModelFloatInput | null,
  and?: Array< ModelAlertsConditionInput | null > | null,
  or?: Array< ModelAlertsConditionInput | null > | null,
  not?: ModelAlertsConditionInput | null,
};

export type ModelBuyTypeInput = {
  eq?: BuyType | null,
  ne?: BuyType | null,
};

export type ModelAlertStatusInput = {
  eq?: AlertStatus | null,
  ne?: AlertStatus | null,
};

export type Alerts = {
  __typename: "Alerts",
  id: string,
  buyType: BuyType,
  buyOrBoughtId: string,
  status: AlertStatus,
  buyOrder?: OrderBids | null,
  boughtOrder?: OrderBoughts | null,
  delAfterDealt?: number | null,
  recheckCount?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type OrderBids = {
  __typename: "OrderBids",
  id: string,
  pairID: string,
  buyPercent: number,
  originalBuyPrice: number,
  buyPrice: number,
  highPrice: number,
  buyHit?: boolean | null,
  bidTime: number,
  buyColor: string,
  invested?: number | null,
  stoploss?: boolean | null,
  stoplossPrice?: number | null,
  baseType: string,
  alerts?: Alerts | null,
  pairInfo?: PairInfo | null,
  createdAt: string,
  updatedAt: string,
};

export type PairInfo = {
  __typename: "PairInfo",
  id: string,
  Coin?: Coin | null,
  notation: string,
  alertPrice?: number | null,
  breakEvenStopLoss: boolean,
  currentPrice: number,
  higestPrice: number,
  highsAndLows:  Array<HighLowPrice | null >,
  initialBalance: number,
  dontTrade?: boolean | null,
  relativeLow: number,
  relativeHigh: number,
  reservedBalance: number,
  baseType: string,
  layeredBuyBids?: ModelOrderBidsConnection | null,
  layeredBuyBoughts?: ModelOrderBoughtsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Coin = {
  __typename: "Coin",
  id: string,
  is_active: boolean,
  is_new: boolean,
  name: string,
  rank: number,
  symbol: string,
  type: string,
  baseType: string,
  pairs?: ModelPairInfoConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelPairInfoConnection = {
  __typename: "ModelPairInfoConnection",
  items:  Array<PairInfo | null >,
  nextToken?: string | null,
};

export type HighLowPrice = {
  __typename: "HighLowPrice",
  price: number,
  time: number,
  priceType: PriceType,
};

export enum PriceType {
  low = "low",
  high = "high",
}


export type ModelOrderBidsConnection = {
  __typename: "ModelOrderBidsConnection",
  items:  Array<OrderBids | null >,
  nextToken?: string | null,
};

export type ModelOrderBoughtsConnection = {
  __typename: "ModelOrderBoughtsConnection",
  items:  Array<OrderBoughts | null >,
  nextToken?: string | null,
};

export type OrderBoughts = {
  __typename: "OrderBoughts",
  id: string,
  pairID: string,
  highPrice: number,
  buyOrderInfo?: Order | null,
  sellOrderInfo?: Order | null,
  realBuyOrderPlaced?: boolean | null,
  realSellOrderPlaced?: boolean | null,
  realBuyOrderStatus?: string | null,
  realSellOrderStatus?: string | null,
  buyPercent: number,
  originalBuyPrice: number,
  buyPrice: number,
  bidTime: number,
  buyTime: number,
  buyColor: string,
  stoploss?: boolean | null,
  stoplossPrice?: number | null,
  profitSecured?: number | null,
  profitMoved?: number | null,
  sellTime?: number | null,
  invested?: number | null,
  baseType: string,
  buyTweetId?: string | null,
  sellTweetId?: string | null,
  alerts?: Alerts | null,
  pairInfo?: PairInfo | null,
  createdAt: string,
  updatedAt: string,
};

export type Order = {
  __typename: "Order",
  id?: string | null,
  clientOrderId?: string | null,
  datetime?: string | null,
  timestamp?: number | null,
  lastTradeTimestamp?: string | null,
  status?: OrderStatus | null,
  symbol?: string | null,
  type?: string | null,
  postOnly?: boolean | null,
  timeInForce?: string | null,
  side?: TradeSide | null,
  price?: number | null,
  average?: number | null,
  amount?: number | null,
  filled?: number | null,
  remaining?: number | null,
  cost?: number | null,
};

export enum OrderStatus {
  open = "open",
  closed = "closed",
  canceled = "canceled",
}


export enum TradeSide {
  buy = "buy",
  sell = "sell",
}


export type UpdateAlertsInput = {
  id: string,
  buyType?: BuyType | null,
  buyOrBoughtId?: string | null,
  status?: AlertStatus | null,
  delAfterDealt?: number | null,
  recheckCount?: number | null,
  alertsBuyOrderId?: string | null,
  alertsBoughtOrderId?: string | null,
};

export type DeleteAlertsInput = {
  id: string,
};

export type CreateCoinInput = {
  id?: string | null,
  is_active: boolean,
  is_new: boolean,
  name: string,
  rank: number,
  symbol: string,
  type: string,
  baseType: string,
};

export type ModelCoinConditionInput = {
  is_active?: ModelBooleanInput | null,
  is_new?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  rank?: ModelFloatInput | null,
  symbol?: ModelStringInput | null,
  type?: ModelStringInput | null,
  baseType?: ModelStringInput | null,
  and?: Array< ModelCoinConditionInput | null > | null,
  or?: Array< ModelCoinConditionInput | null > | null,
  not?: ModelCoinConditionInput | null,
};

export type UpdateCoinInput = {
  id: string,
  is_active?: boolean | null,
  is_new?: boolean | null,
  name?: string | null,
  rank?: number | null,
  symbol?: string | null,
  type?: string | null,
  baseType?: string | null,
};

export type DeleteCoinInput = {
  id: string,
};

export type CreatePairInfoInput = {
  id?: string | null,
  notation: string,
  alertPrice?: number | null,
  breakEvenStopLoss: boolean,
  currentPrice: number,
  higestPrice: number,
  highsAndLows: Array< HighLowPriceInput | null >,
  initialBalance: number,
  dontTrade?: boolean | null,
  relativeLow: number,
  relativeHigh: number,
  reservedBalance: number,
  baseType: string,
  pairInfoCoinId?: string | null,
};

export type HighLowPriceInput = {
  price: number,
  time: number,
  priceType: PriceType,
};

export type ModelPairInfoConditionInput = {
  notation?: ModelStringInput | null,
  alertPrice?: ModelFloatInput | null,
  breakEvenStopLoss?: ModelBooleanInput | null,
  currentPrice?: ModelFloatInput | null,
  higestPrice?: ModelFloatInput | null,
  initialBalance?: ModelFloatInput | null,
  dontTrade?: ModelBooleanInput | null,
  relativeLow?: ModelFloatInput | null,
  relativeHigh?: ModelFloatInput | null,
  reservedBalance?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  and?: Array< ModelPairInfoConditionInput | null > | null,
  or?: Array< ModelPairInfoConditionInput | null > | null,
  not?: ModelPairInfoConditionInput | null,
};

export type UpdatePairInfoInput = {
  id: string,
  notation?: string | null,
  alertPrice?: number | null,
  breakEvenStopLoss?: boolean | null,
  currentPrice?: number | null,
  higestPrice?: number | null,
  highsAndLows?: Array< HighLowPriceInput | null > | null,
  initialBalance?: number | null,
  dontTrade?: boolean | null,
  relativeLow?: number | null,
  relativeHigh?: number | null,
  reservedBalance?: number | null,
  baseType?: string | null,
  pairInfoCoinId?: string | null,
};

export type DeletePairInfoInput = {
  id: string,
};

export type CreateOrderBidsInput = {
  id?: string | null,
  pairID: string,
  buyPercent: number,
  originalBuyPrice: number,
  buyPrice: number,
  highPrice: number,
  buyHit?: boolean | null,
  bidTime: number,
  buyColor: string,
  invested?: number | null,
  stoploss?: boolean | null,
  stoplossPrice?: number | null,
  baseType: string,
  orderBidsAlertsId?: string | null,
  orderBidsPairInfoId?: string | null,
};

export type ModelOrderBidsConditionInput = {
  pairID?: ModelStringInput | null,
  buyPercent?: ModelFloatInput | null,
  originalBuyPrice?: ModelFloatInput | null,
  buyPrice?: ModelFloatInput | null,
  highPrice?: ModelFloatInput | null,
  buyHit?: ModelBooleanInput | null,
  bidTime?: ModelFloatInput | null,
  buyColor?: ModelStringInput | null,
  invested?: ModelFloatInput | null,
  stoploss?: ModelBooleanInput | null,
  stoplossPrice?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  and?: Array< ModelOrderBidsConditionInput | null > | null,
  or?: Array< ModelOrderBidsConditionInput | null > | null,
  not?: ModelOrderBidsConditionInput | null,
};

export type UpdateOrderBidsInput = {
  id: string,
  pairID?: string | null,
  buyPercent?: number | null,
  originalBuyPrice?: number | null,
  buyPrice?: number | null,
  highPrice?: number | null,
  buyHit?: boolean | null,
  bidTime?: number | null,
  buyColor?: string | null,
  invested?: number | null,
  stoploss?: boolean | null,
  stoplossPrice?: number | null,
  baseType?: string | null,
  orderBidsAlertsId?: string | null,
  orderBidsPairInfoId?: string | null,
};

export type DeleteOrderBidsInput = {
  id: string,
};

export type CreateOrderBoughtsInput = {
  id?: string | null,
  pairID: string,
  highPrice: number,
  buyOrderInfo?: OrderInput | null,
  sellOrderInfo?: OrderInput | null,
  realBuyOrderPlaced?: boolean | null,
  realSellOrderPlaced?: boolean | null,
  realBuyOrderStatus?: string | null,
  realSellOrderStatus?: string | null,
  buyPercent: number,
  originalBuyPrice: number,
  buyPrice: number,
  bidTime: number,
  buyTime: number,
  buyColor: string,
  stoploss?: boolean | null,
  stoplossPrice?: number | null,
  profitSecured?: number | null,
  profitMoved?: number | null,
  sellTime?: number | null,
  invested?: number | null,
  baseType: string,
  buyTweetId?: string | null,
  sellTweetId?: string | null,
  orderBoughtsAlertsId?: string | null,
  orderBoughtsPairInfoId?: string | null,
};

export type OrderInput = {
  id?: string | null,
  clientOrderId?: string | null,
  datetime?: string | null,
  timestamp?: number | null,
  lastTradeTimestamp?: string | null,
  status?: OrderStatus | null,
  symbol?: string | null,
  type?: string | null,
  postOnly?: boolean | null,
  timeInForce?: string | null,
  side?: TradeSide | null,
  price?: number | null,
  average?: number | null,
  amount?: number | null,
  filled?: number | null,
  remaining?: number | null,
  cost?: number | null,
};

export type ModelOrderBoughtsConditionInput = {
  pairID?: ModelStringInput | null,
  highPrice?: ModelFloatInput | null,
  realBuyOrderPlaced?: ModelBooleanInput | null,
  realSellOrderPlaced?: ModelBooleanInput | null,
  realBuyOrderStatus?: ModelStringInput | null,
  realSellOrderStatus?: ModelStringInput | null,
  buyPercent?: ModelFloatInput | null,
  originalBuyPrice?: ModelFloatInput | null,
  buyPrice?: ModelFloatInput | null,
  bidTime?: ModelFloatInput | null,
  buyTime?: ModelFloatInput | null,
  buyColor?: ModelStringInput | null,
  stoploss?: ModelBooleanInput | null,
  stoplossPrice?: ModelFloatInput | null,
  profitSecured?: ModelFloatInput | null,
  profitMoved?: ModelFloatInput | null,
  sellTime?: ModelFloatInput | null,
  invested?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  buyTweetId?: ModelStringInput | null,
  sellTweetId?: ModelStringInput | null,
  and?: Array< ModelOrderBoughtsConditionInput | null > | null,
  or?: Array< ModelOrderBoughtsConditionInput | null > | null,
  not?: ModelOrderBoughtsConditionInput | null,
};

export type UpdateOrderBoughtsInput = {
  id: string,
  pairID?: string | null,
  highPrice?: number | null,
  buyOrderInfo?: OrderInput | null,
  sellOrderInfo?: OrderInput | null,
  realBuyOrderPlaced?: boolean | null,
  realSellOrderPlaced?: boolean | null,
  realBuyOrderStatus?: string | null,
  realSellOrderStatus?: string | null,
  buyPercent?: number | null,
  originalBuyPrice?: number | null,
  buyPrice?: number | null,
  bidTime?: number | null,
  buyTime?: number | null,
  buyColor?: string | null,
  stoploss?: boolean | null,
  stoplossPrice?: number | null,
  profitSecured?: number | null,
  profitMoved?: number | null,
  sellTime?: number | null,
  invested?: number | null,
  baseType?: string | null,
  buyTweetId?: string | null,
  sellTweetId?: string | null,
  orderBoughtsAlertsId?: string | null,
  orderBoughtsPairInfoId?: string | null,
};

export type DeleteOrderBoughtsInput = {
  id: string,
};

export type CreatePortfolioInput = {
  id?: string | null,
  date: number,
  portfolioValueUSD: number,
  portfolioValueBTC: number,
  totalMarketCapUSD: number,
  totalCryptosHeld?: number | null,
  notableEvents?: string | null,
};

export type ModelPortfolioConditionInput = {
  date?: ModelFloatInput | null,
  portfolioValueUSD?: ModelFloatInput | null,
  portfolioValueBTC?: ModelFloatInput | null,
  totalMarketCapUSD?: ModelFloatInput | null,
  totalCryptosHeld?: ModelIntInput | null,
  notableEvents?: ModelStringInput | null,
  and?: Array< ModelPortfolioConditionInput | null > | null,
  or?: Array< ModelPortfolioConditionInput | null > | null,
  not?: ModelPortfolioConditionInput | null,
};

export type Portfolio = {
  __typename: "Portfolio",
  id: string,
  date: number,
  portfolioValueUSD: number,
  portfolioValueBTC: number,
  totalMarketCapUSD: number,
  totalCryptosHeld?: number | null,
  notableEvents?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePortfolioInput = {
  id: string,
  date?: number | null,
  portfolioValueUSD?: number | null,
  portfolioValueBTC?: number | null,
  totalMarketCapUSD?: number | null,
  totalCryptosHeld?: number | null,
  notableEvents?: string | null,
};

export type DeletePortfolioInput = {
  id: string,
};

export type ModelSettingsFilterInput = {
  id?: ModelIDInput | null,
  noOflayersToPlace?: ModelFloatInput | null,
  noOfLayersToSkip?: ModelFloatInput | null,
  fatFingerDropPerc?: ModelFloatInput | null,
  skipAlltrades?: ModelBooleanInput | null,
  skipAllBtctrades?: ModelBooleanInput | null,
  skipAllUsdtrades?: ModelBooleanInput | null,
  collectFreeCoins?: ModelBooleanInput | null,
  lastAlertUpdatedDate?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  marketCycle?: ModelMarketCycleInput | null,
  and?: Array< ModelSettingsFilterInput | null > | null,
  or?: Array< ModelSettingsFilterInput | null > | null,
  not?: ModelSettingsFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSettingsConnection = {
  __typename: "ModelSettingsConnection",
  items:  Array<Settings | null >,
  nextToken?: string | null,
};

export type ModelBalancesFilterInput = {
  id?: ModelIDInput | null,
  lastUpdated?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  and?: Array< ModelBalancesFilterInput | null > | null,
  or?: Array< ModelBalancesFilterInput | null > | null,
  not?: ModelBalancesFilterInput | null,
};

export type ModelBalancesConnection = {
  __typename: "ModelBalancesConnection",
  items:  Array<Balances | null >,
  nextToken?: string | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  coinID?: ModelStringInput | null,
  notation?: ModelTradeNotationsInput | null,
  type?: ModelNotificationTypeInput | null,
  message?: ModelStringInput | null,
  seen?: ModelBooleanInput | null,
  date?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  delAfterDealt?: ModelIntInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelAlertsFilterInput = {
  id?: ModelIDInput | null,
  buyType?: ModelBuyTypeInput | null,
  buyOrBoughtId?: ModelStringInput | null,
  status?: ModelAlertStatusInput | null,
  delAfterDealt?: ModelIntInput | null,
  recheckCount?: ModelFloatInput | null,
  and?: Array< ModelAlertsFilterInput | null > | null,
  or?: Array< ModelAlertsFilterInput | null > | null,
  not?: ModelAlertsFilterInput | null,
};

export type ModelAlertsConnection = {
  __typename: "ModelAlertsConnection",
  items:  Array<Alerts | null >,
  nextToken?: string | null,
};

export type ModelCoinFilterInput = {
  id?: ModelIDInput | null,
  is_active?: ModelBooleanInput | null,
  is_new?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  rank?: ModelFloatInput | null,
  symbol?: ModelStringInput | null,
  type?: ModelStringInput | null,
  baseType?: ModelStringInput | null,
  and?: Array< ModelCoinFilterInput | null > | null,
  or?: Array< ModelCoinFilterInput | null > | null,
  not?: ModelCoinFilterInput | null,
};

export type ModelCoinConnection = {
  __typename: "ModelCoinConnection",
  items:  Array<Coin | null >,
  nextToken?: string | null,
};

export type ModelPairInfoFilterInput = {
  id?: ModelIDInput | null,
  notation?: ModelStringInput | null,
  alertPrice?: ModelFloatInput | null,
  breakEvenStopLoss?: ModelBooleanInput | null,
  currentPrice?: ModelFloatInput | null,
  higestPrice?: ModelFloatInput | null,
  initialBalance?: ModelFloatInput | null,
  dontTrade?: ModelBooleanInput | null,
  relativeLow?: ModelFloatInput | null,
  relativeHigh?: ModelFloatInput | null,
  reservedBalance?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  and?: Array< ModelPairInfoFilterInput | null > | null,
  or?: Array< ModelPairInfoFilterInput | null > | null,
  not?: ModelPairInfoFilterInput | null,
};

export type ModelOrderBidsFilterInput = {
  id?: ModelIDInput | null,
  pairID?: ModelStringInput | null,
  buyPercent?: ModelFloatInput | null,
  originalBuyPrice?: ModelFloatInput | null,
  buyPrice?: ModelFloatInput | null,
  highPrice?: ModelFloatInput | null,
  buyHit?: ModelBooleanInput | null,
  bidTime?: ModelFloatInput | null,
  buyColor?: ModelStringInput | null,
  invested?: ModelFloatInput | null,
  stoploss?: ModelBooleanInput | null,
  stoplossPrice?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  and?: Array< ModelOrderBidsFilterInput | null > | null,
  or?: Array< ModelOrderBidsFilterInput | null > | null,
  not?: ModelOrderBidsFilterInput | null,
};

export type ModelOrderBoughtsFilterInput = {
  id?: ModelIDInput | null,
  pairID?: ModelStringInput | null,
  highPrice?: ModelFloatInput | null,
  realBuyOrderPlaced?: ModelBooleanInput | null,
  realSellOrderPlaced?: ModelBooleanInput | null,
  realBuyOrderStatus?: ModelStringInput | null,
  realSellOrderStatus?: ModelStringInput | null,
  buyPercent?: ModelFloatInput | null,
  originalBuyPrice?: ModelFloatInput | null,
  buyPrice?: ModelFloatInput | null,
  bidTime?: ModelFloatInput | null,
  buyTime?: ModelFloatInput | null,
  buyColor?: ModelStringInput | null,
  stoploss?: ModelBooleanInput | null,
  stoplossPrice?: ModelFloatInput | null,
  profitSecured?: ModelFloatInput | null,
  profitMoved?: ModelFloatInput | null,
  sellTime?: ModelFloatInput | null,
  invested?: ModelFloatInput | null,
  baseType?: ModelStringInput | null,
  buyTweetId?: ModelStringInput | null,
  sellTweetId?: ModelStringInput | null,
  and?: Array< ModelOrderBoughtsFilterInput | null > | null,
  or?: Array< ModelOrderBoughtsFilterInput | null > | null,
  not?: ModelOrderBoughtsFilterInput | null,
};

export type ModelPortfolioFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelFloatInput | null,
  portfolioValueUSD?: ModelFloatInput | null,
  portfolioValueBTC?: ModelFloatInput | null,
  totalMarketCapUSD?: ModelFloatInput | null,
  totalCryptosHeld?: ModelIntInput | null,
  notableEvents?: ModelStringInput | null,
  and?: Array< ModelPortfolioFilterInput | null > | null,
  or?: Array< ModelPortfolioFilterInput | null > | null,
  not?: ModelPortfolioFilterInput | null,
};

export type ModelPortfolioConnection = {
  __typename: "ModelPortfolioConnection",
  items:  Array<Portfolio | null >,
  nextToken?: string | null,
};

export type CreateSettingsMutationVariables = {
  input: CreateSettingsInput,
  condition?: ModelSettingsConditionInput | null,
};

export type CreateSettingsMutation = {
  createSettings?:  {
    __typename: "Settings",
    id: string,
    noOflayersToPlace: number,
    noOfLayersToSkip: number,
    fatFingerDropPerc: number,
    skipAlltrades: boolean,
    skipAllBtctrades: boolean,
    skipAllUsdtrades: boolean,
    collectFreeCoins: boolean,
    lastAlertUpdatedDate: number,
    baseType: string,
    marketCycle?: MarketCycle | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSettingsMutationVariables = {
  input: UpdateSettingsInput,
  condition?: ModelSettingsConditionInput | null,
};

export type UpdateSettingsMutation = {
  updateSettings?:  {
    __typename: "Settings",
    id: string,
    noOflayersToPlace: number,
    noOfLayersToSkip: number,
    fatFingerDropPerc: number,
    skipAlltrades: boolean,
    skipAllBtctrades: boolean,
    skipAllUsdtrades: boolean,
    collectFreeCoins: boolean,
    lastAlertUpdatedDate: number,
    baseType: string,
    marketCycle?: MarketCycle | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSettingsMutationVariables = {
  input: DeleteSettingsInput,
  condition?: ModelSettingsConditionInput | null,
};

export type DeleteSettingsMutation = {
  deleteSettings?:  {
    __typename: "Settings",
    id: string,
    noOflayersToPlace: number,
    noOfLayersToSkip: number,
    fatFingerDropPerc: number,
    skipAlltrades: boolean,
    skipAllBtctrades: boolean,
    skipAllUsdtrades: boolean,
    collectFreeCoins: boolean,
    lastAlertUpdatedDate: number,
    baseType: string,
    marketCycle?: MarketCycle | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBalancesMutationVariables = {
  input: CreateBalancesInput,
  condition?: ModelBalancesConditionInput | null,
};

export type CreateBalancesMutation = {
  createBalances?:  {
    __typename: "Balances",
    id: string,
    btc:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    usd:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    lastUpdated: number,
    baseType: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBalancesMutationVariables = {
  input: UpdateBalancesInput,
  condition?: ModelBalancesConditionInput | null,
};

export type UpdateBalancesMutation = {
  updateBalances?:  {
    __typename: "Balances",
    id: string,
    btc:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    usd:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    lastUpdated: number,
    baseType: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBalancesMutationVariables = {
  input: DeleteBalancesInput,
  condition?: ModelBalancesConditionInput | null,
};

export type DeleteBalancesMutation = {
  deleteBalances?:  {
    __typename: "Balances",
    id: string,
    btc:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    usd:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    lastUpdated: number,
    baseType: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    coinID?: string | null,
    notation: TradeNotations,
    type: NotificationType,
    message: string,
    seen: boolean,
    date: number,
    baseType: string,
    delAfterDealt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    coinID?: string | null,
    notation: TradeNotations,
    type: NotificationType,
    message: string,
    seen: boolean,
    date: number,
    baseType: string,
    delAfterDealt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    coinID?: string | null,
    notation: TradeNotations,
    type: NotificationType,
    message: string,
    seen: boolean,
    date: number,
    baseType: string,
    delAfterDealt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAlertsMutationVariables = {
  input: CreateAlertsInput,
  condition?: ModelAlertsConditionInput | null,
};

export type CreateAlertsMutation = {
  createAlerts?:  {
    __typename: "Alerts",
    id: string,
    buyType: BuyType,
    buyOrBoughtId: string,
    status: AlertStatus,
    buyOrder?:  {
      __typename: "OrderBids",
      id: string,
      pairID: string,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      highPrice: number,
      buyHit?: boolean | null,
      bidTime: number,
      buyColor: string,
      invested?: number | null,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    boughtOrder?:  {
      __typename: "OrderBoughts",
      id: string,
      pairID: string,
      highPrice: number,
      realBuyOrderPlaced?: boolean | null,
      realSellOrderPlaced?: boolean | null,
      realBuyOrderStatus?: string | null,
      realSellOrderStatus?: string | null,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      bidTime: number,
      buyTime: number,
      buyColor: string,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      profitSecured?: number | null,
      profitMoved?: number | null,
      sellTime?: number | null,
      invested?: number | null,
      baseType: string,
      buyTweetId?: string | null,
      sellTweetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    delAfterDealt?: number | null,
    recheckCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAlertsMutationVariables = {
  input: UpdateAlertsInput,
  condition?: ModelAlertsConditionInput | null,
};

export type UpdateAlertsMutation = {
  updateAlerts?:  {
    __typename: "Alerts",
    id: string,
    buyType: BuyType,
    buyOrBoughtId: string,
    status: AlertStatus,
    buyOrder?:  {
      __typename: "OrderBids",
      id: string,
      pairID: string,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      highPrice: number,
      buyHit?: boolean | null,
      bidTime: number,
      buyColor: string,
      invested?: number | null,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    boughtOrder?:  {
      __typename: "OrderBoughts",
      id: string,
      pairID: string,
      highPrice: number,
      realBuyOrderPlaced?: boolean | null,
      realSellOrderPlaced?: boolean | null,
      realBuyOrderStatus?: string | null,
      realSellOrderStatus?: string | null,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      bidTime: number,
      buyTime: number,
      buyColor: string,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      profitSecured?: number | null,
      profitMoved?: number | null,
      sellTime?: number | null,
      invested?: number | null,
      baseType: string,
      buyTweetId?: string | null,
      sellTweetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    delAfterDealt?: number | null,
    recheckCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAlertsMutationVariables = {
  input: DeleteAlertsInput,
  condition?: ModelAlertsConditionInput | null,
};

export type DeleteAlertsMutation = {
  deleteAlerts?:  {
    __typename: "Alerts",
    id: string,
    buyType: BuyType,
    buyOrBoughtId: string,
    status: AlertStatus,
    buyOrder?:  {
      __typename: "OrderBids",
      id: string,
      pairID: string,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      highPrice: number,
      buyHit?: boolean | null,
      bidTime: number,
      buyColor: string,
      invested?: number | null,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    boughtOrder?:  {
      __typename: "OrderBoughts",
      id: string,
      pairID: string,
      highPrice: number,
      realBuyOrderPlaced?: boolean | null,
      realSellOrderPlaced?: boolean | null,
      realBuyOrderStatus?: string | null,
      realSellOrderStatus?: string | null,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      bidTime: number,
      buyTime: number,
      buyColor: string,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      profitSecured?: number | null,
      profitMoved?: number | null,
      sellTime?: number | null,
      invested?: number | null,
      baseType: string,
      buyTweetId?: string | null,
      sellTweetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    delAfterDealt?: number | null,
    recheckCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCoinMutationVariables = {
  input: CreateCoinInput,
  condition?: ModelCoinConditionInput | null,
};

export type CreateCoinMutation = {
  createCoin?:  {
    __typename: "Coin",
    id: string,
    is_active: boolean,
    is_new: boolean,
    name: string,
    rank: number,
    symbol: string,
    type: string,
    baseType: string,
    pairs?:  {
      __typename: "ModelPairInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCoinMutationVariables = {
  input: UpdateCoinInput,
  condition?: ModelCoinConditionInput | null,
};

export type UpdateCoinMutation = {
  updateCoin?:  {
    __typename: "Coin",
    id: string,
    is_active: boolean,
    is_new: boolean,
    name: string,
    rank: number,
    symbol: string,
    type: string,
    baseType: string,
    pairs?:  {
      __typename: "ModelPairInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCoinMutationVariables = {
  input: DeleteCoinInput,
  condition?: ModelCoinConditionInput | null,
};

export type DeleteCoinMutation = {
  deleteCoin?:  {
    __typename: "Coin",
    id: string,
    is_active: boolean,
    is_new: boolean,
    name: string,
    rank: number,
    symbol: string,
    type: string,
    baseType: string,
    pairs?:  {
      __typename: "ModelPairInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePairInfoMutationVariables = {
  input: CreatePairInfoInput,
  condition?: ModelPairInfoConditionInput | null,
};

export type CreatePairInfoMutation = {
  createPairInfo?:  {
    __typename: "PairInfo",
    id: string,
    Coin?:  {
      __typename: "Coin",
      id: string,
      is_active: boolean,
      is_new: boolean,
      name: string,
      rank: number,
      symbol: string,
      type: string,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    notation: string,
    alertPrice?: number | null,
    breakEvenStopLoss: boolean,
    currentPrice: number,
    higestPrice: number,
    highsAndLows:  Array< {
      __typename: "HighLowPrice",
      price: number,
      time: number,
      priceType: PriceType,
    } | null >,
    initialBalance: number,
    dontTrade?: boolean | null,
    relativeLow: number,
    relativeHigh: number,
    reservedBalance: number,
    baseType: string,
    layeredBuyBids?:  {
      __typename: "ModelOrderBidsConnection",
      nextToken?: string | null,
    } | null,
    layeredBuyBoughts?:  {
      __typename: "ModelOrderBoughtsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePairInfoMutationVariables = {
  input: UpdatePairInfoInput,
  condition?: ModelPairInfoConditionInput | null,
};

export type UpdatePairInfoMutation = {
  updatePairInfo?:  {
    __typename: "PairInfo",
    id: string,
    Coin?:  {
      __typename: "Coin",
      id: string,
      is_active: boolean,
      is_new: boolean,
      name: string,
      rank: number,
      symbol: string,
      type: string,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    notation: string,
    alertPrice?: number | null,
    breakEvenStopLoss: boolean,
    currentPrice: number,
    higestPrice: number,
    highsAndLows:  Array< {
      __typename: "HighLowPrice",
      price: number,
      time: number,
      priceType: PriceType,
    } | null >,
    initialBalance: number,
    dontTrade?: boolean | null,
    relativeLow: number,
    relativeHigh: number,
    reservedBalance: number,
    baseType: string,
    layeredBuyBids?:  {
      __typename: "ModelOrderBidsConnection",
      nextToken?: string | null,
    } | null,
    layeredBuyBoughts?:  {
      __typename: "ModelOrderBoughtsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePairInfoMutationVariables = {
  input: DeletePairInfoInput,
  condition?: ModelPairInfoConditionInput | null,
};

export type DeletePairInfoMutation = {
  deletePairInfo?:  {
    __typename: "PairInfo",
    id: string,
    Coin?:  {
      __typename: "Coin",
      id: string,
      is_active: boolean,
      is_new: boolean,
      name: string,
      rank: number,
      symbol: string,
      type: string,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    notation: string,
    alertPrice?: number | null,
    breakEvenStopLoss: boolean,
    currentPrice: number,
    higestPrice: number,
    highsAndLows:  Array< {
      __typename: "HighLowPrice",
      price: number,
      time: number,
      priceType: PriceType,
    } | null >,
    initialBalance: number,
    dontTrade?: boolean | null,
    relativeLow: number,
    relativeHigh: number,
    reservedBalance: number,
    baseType: string,
    layeredBuyBids?:  {
      __typename: "ModelOrderBidsConnection",
      nextToken?: string | null,
    } | null,
    layeredBuyBoughts?:  {
      __typename: "ModelOrderBoughtsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrderBidsMutationVariables = {
  input: CreateOrderBidsInput,
  condition?: ModelOrderBidsConditionInput | null,
};

export type CreateOrderBidsMutation = {
  createOrderBids?:  {
    __typename: "OrderBids",
    id: string,
    pairID: string,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    highPrice: number,
    buyHit?: boolean | null,
    bidTime: number,
    buyColor: string,
    invested?: number | null,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    baseType: string,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrderBidsMutationVariables = {
  input: UpdateOrderBidsInput,
  condition?: ModelOrderBidsConditionInput | null,
};

export type UpdateOrderBidsMutation = {
  updateOrderBids?:  {
    __typename: "OrderBids",
    id: string,
    pairID: string,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    highPrice: number,
    buyHit?: boolean | null,
    bidTime: number,
    buyColor: string,
    invested?: number | null,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    baseType: string,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrderBidsMutationVariables = {
  input: DeleteOrderBidsInput,
  condition?: ModelOrderBidsConditionInput | null,
};

export type DeleteOrderBidsMutation = {
  deleteOrderBids?:  {
    __typename: "OrderBids",
    id: string,
    pairID: string,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    highPrice: number,
    buyHit?: boolean | null,
    bidTime: number,
    buyColor: string,
    invested?: number | null,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    baseType: string,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrderBoughtsMutationVariables = {
  input: CreateOrderBoughtsInput,
  condition?: ModelOrderBoughtsConditionInput | null,
};

export type CreateOrderBoughtsMutation = {
  createOrderBoughts?:  {
    __typename: "OrderBoughts",
    id: string,
    pairID: string,
    highPrice: number,
    buyOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    sellOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    realBuyOrderPlaced?: boolean | null,
    realSellOrderPlaced?: boolean | null,
    realBuyOrderStatus?: string | null,
    realSellOrderStatus?: string | null,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    bidTime: number,
    buyTime: number,
    buyColor: string,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    profitSecured?: number | null,
    profitMoved?: number | null,
    sellTime?: number | null,
    invested?: number | null,
    baseType: string,
    buyTweetId?: string | null,
    sellTweetId?: string | null,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrderBoughtsMutationVariables = {
  input: UpdateOrderBoughtsInput,
  condition?: ModelOrderBoughtsConditionInput | null,
};

export type UpdateOrderBoughtsMutation = {
  updateOrderBoughts?:  {
    __typename: "OrderBoughts",
    id: string,
    pairID: string,
    highPrice: number,
    buyOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    sellOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    realBuyOrderPlaced?: boolean | null,
    realSellOrderPlaced?: boolean | null,
    realBuyOrderStatus?: string | null,
    realSellOrderStatus?: string | null,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    bidTime: number,
    buyTime: number,
    buyColor: string,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    profitSecured?: number | null,
    profitMoved?: number | null,
    sellTime?: number | null,
    invested?: number | null,
    baseType: string,
    buyTweetId?: string | null,
    sellTweetId?: string | null,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrderBoughtsMutationVariables = {
  input: DeleteOrderBoughtsInput,
  condition?: ModelOrderBoughtsConditionInput | null,
};

export type DeleteOrderBoughtsMutation = {
  deleteOrderBoughts?:  {
    __typename: "OrderBoughts",
    id: string,
    pairID: string,
    highPrice: number,
    buyOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    sellOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    realBuyOrderPlaced?: boolean | null,
    realSellOrderPlaced?: boolean | null,
    realBuyOrderStatus?: string | null,
    realSellOrderStatus?: string | null,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    bidTime: number,
    buyTime: number,
    buyColor: string,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    profitSecured?: number | null,
    profitMoved?: number | null,
    sellTime?: number | null,
    invested?: number | null,
    baseType: string,
    buyTweetId?: string | null,
    sellTweetId?: string | null,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePortfolioMutationVariables = {
  input: CreatePortfolioInput,
  condition?: ModelPortfolioConditionInput | null,
};

export type CreatePortfolioMutation = {
  createPortfolio?:  {
    __typename: "Portfolio",
    id: string,
    date: number,
    portfolioValueUSD: number,
    portfolioValueBTC: number,
    totalMarketCapUSD: number,
    totalCryptosHeld?: number | null,
    notableEvents?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePortfolioMutationVariables = {
  input: UpdatePortfolioInput,
  condition?: ModelPortfolioConditionInput | null,
};

export type UpdatePortfolioMutation = {
  updatePortfolio?:  {
    __typename: "Portfolio",
    id: string,
    date: number,
    portfolioValueUSD: number,
    portfolioValueBTC: number,
    totalMarketCapUSD: number,
    totalCryptosHeld?: number | null,
    notableEvents?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePortfolioMutationVariables = {
  input: DeletePortfolioInput,
  condition?: ModelPortfolioConditionInput | null,
};

export type DeletePortfolioMutation = {
  deletePortfolio?:  {
    __typename: "Portfolio",
    id: string,
    date: number,
    portfolioValueUSD: number,
    portfolioValueBTC: number,
    totalMarketCapUSD: number,
    totalCryptosHeld?: number | null,
    notableEvents?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetSettingsQueryVariables = {
  id: string,
};

export type GetSettingsQuery = {
  getSettings?:  {
    __typename: "Settings",
    id: string,
    noOflayersToPlace: number,
    noOfLayersToSkip: number,
    fatFingerDropPerc: number,
    skipAlltrades: boolean,
    skipAllBtctrades: boolean,
    skipAllUsdtrades: boolean,
    collectFreeCoins: boolean,
    lastAlertUpdatedDate: number,
    baseType: string,
    marketCycle?: MarketCycle | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSettingsQueryVariables = {
  filter?: ModelSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSettingsQuery = {
  listSettings?:  {
    __typename: "ModelSettingsConnection",
    items:  Array< {
      __typename: "Settings",
      id: string,
      noOflayersToPlace: number,
      noOfLayersToSkip: number,
      fatFingerDropPerc: number,
      skipAlltrades: boolean,
      skipAllBtctrades: boolean,
      skipAllUsdtrades: boolean,
      collectFreeCoins: boolean,
      lastAlertUpdatedDate: number,
      baseType: string,
      marketCycle?: MarketCycle | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBalancesQueryVariables = {
  id: string,
};

export type GetBalancesQuery = {
  getBalances?:  {
    __typename: "Balances",
    id: string,
    btc:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    usd:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    lastUpdated: number,
    baseType: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBalancesQueryVariables = {
  filter?: ModelBalancesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBalancesQuery = {
  listBalances?:  {
    __typename: "ModelBalancesConnection",
    items:  Array< {
      __typename: "Balances",
      id: string,
      lastUpdated: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    coinID?: string | null,
    notation: TradeNotations,
    type: NotificationType,
    message: string,
    seen: boolean,
    date: number,
    baseType: string,
    delAfterDealt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      coinID?: string | null,
      notation: TradeNotations,
      type: NotificationType,
      message: string,
      seen: boolean,
      date: number,
      baseType: string,
      delAfterDealt?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAlertsQueryVariables = {
  id: string,
};

export type GetAlertsQuery = {
  getAlerts?:  {
    __typename: "Alerts",
    id: string,
    buyType: BuyType,
    buyOrBoughtId: string,
    status: AlertStatus,
    buyOrder?:  {
      __typename: "OrderBids",
      id: string,
      pairID: string,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      highPrice: number,
      buyHit?: boolean | null,
      bidTime: number,
      buyColor: string,
      invested?: number | null,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    boughtOrder?:  {
      __typename: "OrderBoughts",
      id: string,
      pairID: string,
      highPrice: number,
      realBuyOrderPlaced?: boolean | null,
      realSellOrderPlaced?: boolean | null,
      realBuyOrderStatus?: string | null,
      realSellOrderStatus?: string | null,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      bidTime: number,
      buyTime: number,
      buyColor: string,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      profitSecured?: number | null,
      profitMoved?: number | null,
      sellTime?: number | null,
      invested?: number | null,
      baseType: string,
      buyTweetId?: string | null,
      sellTweetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    delAfterDealt?: number | null,
    recheckCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAlertsQueryVariables = {
  filter?: ModelAlertsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAlertsQuery = {
  listAlerts?:  {
    __typename: "ModelAlertsConnection",
    items:  Array< {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCoinQueryVariables = {
  id: string,
};

export type GetCoinQuery = {
  getCoin?:  {
    __typename: "Coin",
    id: string,
    is_active: boolean,
    is_new: boolean,
    name: string,
    rank: number,
    symbol: string,
    type: string,
    baseType: string,
    pairs?:  {
      __typename: "ModelPairInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCoinsQueryVariables = {
  filter?: ModelCoinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCoinsQuery = {
  listCoins?:  {
    __typename: "ModelCoinConnection",
    items:  Array< {
      __typename: "Coin",
      id: string,
      is_active: boolean,
      is_new: boolean,
      name: string,
      rank: number,
      symbol: string,
      type: string,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPairInfoQueryVariables = {
  id: string,
};

export type GetPairInfoQuery = {
  getPairInfo?:  {
    __typename: "PairInfo",
    id: string,
    Coin?:  {
      __typename: "Coin",
      id: string,
      is_active: boolean,
      is_new: boolean,
      name: string,
      rank: number,
      symbol: string,
      type: string,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    notation: string,
    alertPrice?: number | null,
    breakEvenStopLoss: boolean,
    currentPrice: number,
    higestPrice: number,
    highsAndLows:  Array< {
      __typename: "HighLowPrice",
      price: number,
      time: number,
      priceType: PriceType,
    } | null >,
    initialBalance: number,
    dontTrade?: boolean | null,
    relativeLow: number,
    relativeHigh: number,
    reservedBalance: number,
    baseType: string,
    layeredBuyBids?:  {
      __typename: "ModelOrderBidsConnection",
      nextToken?: string | null,
    } | null,
    layeredBuyBoughts?:  {
      __typename: "ModelOrderBoughtsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPairInfosQueryVariables = {
  filter?: ModelPairInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPairInfosQuery = {
  listPairInfos?:  {
    __typename: "ModelPairInfoConnection",
    items:  Array< {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderBidsQueryVariables = {
  id: string,
};

export type GetOrderBidsQuery = {
  getOrderBids?:  {
    __typename: "OrderBids",
    id: string,
    pairID: string,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    highPrice: number,
    buyHit?: boolean | null,
    bidTime: number,
    buyColor: string,
    invested?: number | null,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    baseType: string,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrderBidsQueryVariables = {
  filter?: ModelOrderBidsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderBidsQuery = {
  listOrderBids?:  {
    __typename: "ModelOrderBidsConnection",
    items:  Array< {
      __typename: "OrderBids",
      id: string,
      pairID: string,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      highPrice: number,
      buyHit?: boolean | null,
      bidTime: number,
      buyColor: string,
      invested?: number | null,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderBoughtsQueryVariables = {
  id: string,
};

export type GetOrderBoughtsQuery = {
  getOrderBoughts?:  {
    __typename: "OrderBoughts",
    id: string,
    pairID: string,
    highPrice: number,
    buyOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    sellOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    realBuyOrderPlaced?: boolean | null,
    realSellOrderPlaced?: boolean | null,
    realBuyOrderStatus?: string | null,
    realSellOrderStatus?: string | null,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    bidTime: number,
    buyTime: number,
    buyColor: string,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    profitSecured?: number | null,
    profitMoved?: number | null,
    sellTime?: number | null,
    invested?: number | null,
    baseType: string,
    buyTweetId?: string | null,
    sellTweetId?: string | null,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrderBoughtsQueryVariables = {
  filter?: ModelOrderBoughtsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderBoughtsQuery = {
  listOrderBoughts?:  {
    __typename: "ModelOrderBoughtsConnection",
    items:  Array< {
      __typename: "OrderBoughts",
      id: string,
      pairID: string,
      highPrice: number,
      realBuyOrderPlaced?: boolean | null,
      realSellOrderPlaced?: boolean | null,
      realBuyOrderStatus?: string | null,
      realSellOrderStatus?: string | null,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      bidTime: number,
      buyTime: number,
      buyColor: string,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      profitSecured?: number | null,
      profitMoved?: number | null,
      sellTime?: number | null,
      invested?: number | null,
      baseType: string,
      buyTweetId?: string | null,
      sellTweetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPortfolioQueryVariables = {
  id: string,
};

export type GetPortfolioQuery = {
  getPortfolio?:  {
    __typename: "Portfolio",
    id: string,
    date: number,
    portfolioValueUSD: number,
    portfolioValueBTC: number,
    totalMarketCapUSD: number,
    totalCryptosHeld?: number | null,
    notableEvents?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPortfoliosQueryVariables = {
  filter?: ModelPortfolioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPortfoliosQuery = {
  listPortfolios?:  {
    __typename: "ModelPortfolioConnection",
    items:  Array< {
      __typename: "Portfolio",
      id: string,
      date: number,
      portfolioValueUSD: number,
      portfolioValueBTC: number,
      totalMarketCapUSD: number,
      totalCryptosHeld?: number | null,
      notableEvents?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateSettingsSubscriptionVariables = {
};

export type OnCreateSettingsSubscription = {
  onCreateSettings?:  {
    __typename: "Settings",
    id: string,
    noOflayersToPlace: number,
    noOfLayersToSkip: number,
    fatFingerDropPerc: number,
    skipAlltrades: boolean,
    skipAllBtctrades: boolean,
    skipAllUsdtrades: boolean,
    collectFreeCoins: boolean,
    lastAlertUpdatedDate: number,
    baseType: string,
    marketCycle?: MarketCycle | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSettingsSubscriptionVariables = {
};

export type OnUpdateSettingsSubscription = {
  onUpdateSettings?:  {
    __typename: "Settings",
    id: string,
    noOflayersToPlace: number,
    noOfLayersToSkip: number,
    fatFingerDropPerc: number,
    skipAlltrades: boolean,
    skipAllBtctrades: boolean,
    skipAllUsdtrades: boolean,
    collectFreeCoins: boolean,
    lastAlertUpdatedDate: number,
    baseType: string,
    marketCycle?: MarketCycle | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSettingsSubscriptionVariables = {
};

export type OnDeleteSettingsSubscription = {
  onDeleteSettings?:  {
    __typename: "Settings",
    id: string,
    noOflayersToPlace: number,
    noOfLayersToSkip: number,
    fatFingerDropPerc: number,
    skipAlltrades: boolean,
    skipAllBtctrades: boolean,
    skipAllUsdtrades: boolean,
    collectFreeCoins: boolean,
    lastAlertUpdatedDate: number,
    baseType: string,
    marketCycle?: MarketCycle | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBalancesSubscriptionVariables = {
};

export type OnCreateBalancesSubscription = {
  onCreateBalances?:  {
    __typename: "Balances",
    id: string,
    btc:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    usd:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    lastUpdated: number,
    baseType: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBalancesSubscriptionVariables = {
};

export type OnUpdateBalancesSubscription = {
  onUpdateBalances?:  {
    __typename: "Balances",
    id: string,
    btc:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    usd:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    lastUpdated: number,
    baseType: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBalancesSubscriptionVariables = {
};

export type OnDeleteBalancesSubscription = {
  onDeleteBalances?:  {
    __typename: "Balances",
    id: string,
    btc:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    usd:  {
      __typename: "Balance",
      free: number,
      total: number,
      real: number,
    },
    lastUpdated: number,
    baseType: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    coinID?: string | null,
    notation: TradeNotations,
    type: NotificationType,
    message: string,
    seen: boolean,
    date: number,
    baseType: string,
    delAfterDealt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    coinID?: string | null,
    notation: TradeNotations,
    type: NotificationType,
    message: string,
    seen: boolean,
    date: number,
    baseType: string,
    delAfterDealt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    coinID?: string | null,
    notation: TradeNotations,
    type: NotificationType,
    message: string,
    seen: boolean,
    date: number,
    baseType: string,
    delAfterDealt?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAlertsSubscriptionVariables = {
};

export type OnCreateAlertsSubscription = {
  onCreateAlerts?:  {
    __typename: "Alerts",
    id: string,
    buyType: BuyType,
    buyOrBoughtId: string,
    status: AlertStatus,
    buyOrder?:  {
      __typename: "OrderBids",
      id: string,
      pairID: string,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      highPrice: number,
      buyHit?: boolean | null,
      bidTime: number,
      buyColor: string,
      invested?: number | null,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    boughtOrder?:  {
      __typename: "OrderBoughts",
      id: string,
      pairID: string,
      highPrice: number,
      realBuyOrderPlaced?: boolean | null,
      realSellOrderPlaced?: boolean | null,
      realBuyOrderStatus?: string | null,
      realSellOrderStatus?: string | null,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      bidTime: number,
      buyTime: number,
      buyColor: string,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      profitSecured?: number | null,
      profitMoved?: number | null,
      sellTime?: number | null,
      invested?: number | null,
      baseType: string,
      buyTweetId?: string | null,
      sellTweetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    delAfterDealt?: number | null,
    recheckCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAlertsSubscriptionVariables = {
};

export type OnUpdateAlertsSubscription = {
  onUpdateAlerts?:  {
    __typename: "Alerts",
    id: string,
    buyType: BuyType,
    buyOrBoughtId: string,
    status: AlertStatus,
    buyOrder?:  {
      __typename: "OrderBids",
      id: string,
      pairID: string,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      highPrice: number,
      buyHit?: boolean | null,
      bidTime: number,
      buyColor: string,
      invested?: number | null,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    boughtOrder?:  {
      __typename: "OrderBoughts",
      id: string,
      pairID: string,
      highPrice: number,
      realBuyOrderPlaced?: boolean | null,
      realSellOrderPlaced?: boolean | null,
      realBuyOrderStatus?: string | null,
      realSellOrderStatus?: string | null,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      bidTime: number,
      buyTime: number,
      buyColor: string,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      profitSecured?: number | null,
      profitMoved?: number | null,
      sellTime?: number | null,
      invested?: number | null,
      baseType: string,
      buyTweetId?: string | null,
      sellTweetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    delAfterDealt?: number | null,
    recheckCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAlertsSubscriptionVariables = {
};

export type OnDeleteAlertsSubscription = {
  onDeleteAlerts?:  {
    __typename: "Alerts",
    id: string,
    buyType: BuyType,
    buyOrBoughtId: string,
    status: AlertStatus,
    buyOrder?:  {
      __typename: "OrderBids",
      id: string,
      pairID: string,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      highPrice: number,
      buyHit?: boolean | null,
      bidTime: number,
      buyColor: string,
      invested?: number | null,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    boughtOrder?:  {
      __typename: "OrderBoughts",
      id: string,
      pairID: string,
      highPrice: number,
      realBuyOrderPlaced?: boolean | null,
      realSellOrderPlaced?: boolean | null,
      realBuyOrderStatus?: string | null,
      realSellOrderStatus?: string | null,
      buyPercent: number,
      originalBuyPrice: number,
      buyPrice: number,
      bidTime: number,
      buyTime: number,
      buyColor: string,
      stoploss?: boolean | null,
      stoplossPrice?: number | null,
      profitSecured?: number | null,
      profitMoved?: number | null,
      sellTime?: number | null,
      invested?: number | null,
      baseType: string,
      buyTweetId?: string | null,
      sellTweetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    delAfterDealt?: number | null,
    recheckCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCoinSubscriptionVariables = {
};

export type OnCreateCoinSubscription = {
  onCreateCoin?:  {
    __typename: "Coin",
    id: string,
    is_active: boolean,
    is_new: boolean,
    name: string,
    rank: number,
    symbol: string,
    type: string,
    baseType: string,
    pairs?:  {
      __typename: "ModelPairInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCoinSubscriptionVariables = {
};

export type OnUpdateCoinSubscription = {
  onUpdateCoin?:  {
    __typename: "Coin",
    id: string,
    is_active: boolean,
    is_new: boolean,
    name: string,
    rank: number,
    symbol: string,
    type: string,
    baseType: string,
    pairs?:  {
      __typename: "ModelPairInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCoinSubscriptionVariables = {
};

export type OnDeleteCoinSubscription = {
  onDeleteCoin?:  {
    __typename: "Coin",
    id: string,
    is_active: boolean,
    is_new: boolean,
    name: string,
    rank: number,
    symbol: string,
    type: string,
    baseType: string,
    pairs?:  {
      __typename: "ModelPairInfoConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePairInfoSubscriptionVariables = {
};

export type OnCreatePairInfoSubscription = {
  onCreatePairInfo?:  {
    __typename: "PairInfo",
    id: string,
    Coin?:  {
      __typename: "Coin",
      id: string,
      is_active: boolean,
      is_new: boolean,
      name: string,
      rank: number,
      symbol: string,
      type: string,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    notation: string,
    alertPrice?: number | null,
    breakEvenStopLoss: boolean,
    currentPrice: number,
    higestPrice: number,
    highsAndLows:  Array< {
      __typename: "HighLowPrice",
      price: number,
      time: number,
      priceType: PriceType,
    } | null >,
    initialBalance: number,
    dontTrade?: boolean | null,
    relativeLow: number,
    relativeHigh: number,
    reservedBalance: number,
    baseType: string,
    layeredBuyBids?:  {
      __typename: "ModelOrderBidsConnection",
      nextToken?: string | null,
    } | null,
    layeredBuyBoughts?:  {
      __typename: "ModelOrderBoughtsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePairInfoSubscriptionVariables = {
};

export type OnUpdatePairInfoSubscription = {
  onUpdatePairInfo?:  {
    __typename: "PairInfo",
    id: string,
    Coin?:  {
      __typename: "Coin",
      id: string,
      is_active: boolean,
      is_new: boolean,
      name: string,
      rank: number,
      symbol: string,
      type: string,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    notation: string,
    alertPrice?: number | null,
    breakEvenStopLoss: boolean,
    currentPrice: number,
    higestPrice: number,
    highsAndLows:  Array< {
      __typename: "HighLowPrice",
      price: number,
      time: number,
      priceType: PriceType,
    } | null >,
    initialBalance: number,
    dontTrade?: boolean | null,
    relativeLow: number,
    relativeHigh: number,
    reservedBalance: number,
    baseType: string,
    layeredBuyBids?:  {
      __typename: "ModelOrderBidsConnection",
      nextToken?: string | null,
    } | null,
    layeredBuyBoughts?:  {
      __typename: "ModelOrderBoughtsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePairInfoSubscriptionVariables = {
};

export type OnDeletePairInfoSubscription = {
  onDeletePairInfo?:  {
    __typename: "PairInfo",
    id: string,
    Coin?:  {
      __typename: "Coin",
      id: string,
      is_active: boolean,
      is_new: boolean,
      name: string,
      rank: number,
      symbol: string,
      type: string,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    notation: string,
    alertPrice?: number | null,
    breakEvenStopLoss: boolean,
    currentPrice: number,
    higestPrice: number,
    highsAndLows:  Array< {
      __typename: "HighLowPrice",
      price: number,
      time: number,
      priceType: PriceType,
    } | null >,
    initialBalance: number,
    dontTrade?: boolean | null,
    relativeLow: number,
    relativeHigh: number,
    reservedBalance: number,
    baseType: string,
    layeredBuyBids?:  {
      __typename: "ModelOrderBidsConnection",
      nextToken?: string | null,
    } | null,
    layeredBuyBoughts?:  {
      __typename: "ModelOrderBoughtsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrderBidsSubscriptionVariables = {
};

export type OnCreateOrderBidsSubscription = {
  onCreateOrderBids?:  {
    __typename: "OrderBids",
    id: string,
    pairID: string,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    highPrice: number,
    buyHit?: boolean | null,
    bidTime: number,
    buyColor: string,
    invested?: number | null,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    baseType: string,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderBidsSubscriptionVariables = {
};

export type OnUpdateOrderBidsSubscription = {
  onUpdateOrderBids?:  {
    __typename: "OrderBids",
    id: string,
    pairID: string,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    highPrice: number,
    buyHit?: boolean | null,
    bidTime: number,
    buyColor: string,
    invested?: number | null,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    baseType: string,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderBidsSubscriptionVariables = {
};

export type OnDeleteOrderBidsSubscription = {
  onDeleteOrderBids?:  {
    __typename: "OrderBids",
    id: string,
    pairID: string,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    highPrice: number,
    buyHit?: boolean | null,
    bidTime: number,
    buyColor: string,
    invested?: number | null,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    baseType: string,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrderBoughtsSubscriptionVariables = {
};

export type OnCreateOrderBoughtsSubscription = {
  onCreateOrderBoughts?:  {
    __typename: "OrderBoughts",
    id: string,
    pairID: string,
    highPrice: number,
    buyOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    sellOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    realBuyOrderPlaced?: boolean | null,
    realSellOrderPlaced?: boolean | null,
    realBuyOrderStatus?: string | null,
    realSellOrderStatus?: string | null,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    bidTime: number,
    buyTime: number,
    buyColor: string,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    profitSecured?: number | null,
    profitMoved?: number | null,
    sellTime?: number | null,
    invested?: number | null,
    baseType: string,
    buyTweetId?: string | null,
    sellTweetId?: string | null,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderBoughtsSubscriptionVariables = {
};

export type OnUpdateOrderBoughtsSubscription = {
  onUpdateOrderBoughts?:  {
    __typename: "OrderBoughts",
    id: string,
    pairID: string,
    highPrice: number,
    buyOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    sellOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    realBuyOrderPlaced?: boolean | null,
    realSellOrderPlaced?: boolean | null,
    realBuyOrderStatus?: string | null,
    realSellOrderStatus?: string | null,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    bidTime: number,
    buyTime: number,
    buyColor: string,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    profitSecured?: number | null,
    profitMoved?: number | null,
    sellTime?: number | null,
    invested?: number | null,
    baseType: string,
    buyTweetId?: string | null,
    sellTweetId?: string | null,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderBoughtsSubscriptionVariables = {
};

export type OnDeleteOrderBoughtsSubscription = {
  onDeleteOrderBoughts?:  {
    __typename: "OrderBoughts",
    id: string,
    pairID: string,
    highPrice: number,
    buyOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    sellOrderInfo?:  {
      __typename: "Order",
      id?: string | null,
      clientOrderId?: string | null,
      datetime?: string | null,
      timestamp?: number | null,
      lastTradeTimestamp?: string | null,
      status?: OrderStatus | null,
      symbol?: string | null,
      type?: string | null,
      postOnly?: boolean | null,
      timeInForce?: string | null,
      side?: TradeSide | null,
      price?: number | null,
      average?: number | null,
      amount?: number | null,
      filled?: number | null,
      remaining?: number | null,
      cost?: number | null,
    } | null,
    realBuyOrderPlaced?: boolean | null,
    realSellOrderPlaced?: boolean | null,
    realBuyOrderStatus?: string | null,
    realSellOrderStatus?: string | null,
    buyPercent: number,
    originalBuyPrice: number,
    buyPrice: number,
    bidTime: number,
    buyTime: number,
    buyColor: string,
    stoploss?: boolean | null,
    stoplossPrice?: number | null,
    profitSecured?: number | null,
    profitMoved?: number | null,
    sellTime?: number | null,
    invested?: number | null,
    baseType: string,
    buyTweetId?: string | null,
    sellTweetId?: string | null,
    alerts?:  {
      __typename: "Alerts",
      id: string,
      buyType: BuyType,
      buyOrBoughtId: string,
      status: AlertStatus,
      delAfterDealt?: number | null,
      recheckCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pairInfo?:  {
      __typename: "PairInfo",
      id: string,
      notation: string,
      alertPrice?: number | null,
      breakEvenStopLoss: boolean,
      currentPrice: number,
      higestPrice: number,
      initialBalance: number,
      dontTrade?: boolean | null,
      relativeLow: number,
      relativeHigh: number,
      reservedBalance: number,
      baseType: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePortfolioSubscriptionVariables = {
};

export type OnCreatePortfolioSubscription = {
  onCreatePortfolio?:  {
    __typename: "Portfolio",
    id: string,
    date: number,
    portfolioValueUSD: number,
    portfolioValueBTC: number,
    totalMarketCapUSD: number,
    totalCryptosHeld?: number | null,
    notableEvents?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePortfolioSubscriptionVariables = {
};

export type OnUpdatePortfolioSubscription = {
  onUpdatePortfolio?:  {
    __typename: "Portfolio",
    id: string,
    date: number,
    portfolioValueUSD: number,
    portfolioValueBTC: number,
    totalMarketCapUSD: number,
    totalCryptosHeld?: number | null,
    notableEvents?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePortfolioSubscriptionVariables = {
};

export type OnDeletePortfolioSubscription = {
  onDeletePortfolio?:  {
    __typename: "Portfolio",
    id: string,
    date: number,
    portfolioValueUSD: number,
    portfolioValueBTC: number,
    totalMarketCapUSD: number,
    totalCryptosHeld?: number | null,
    notableEvents?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
