import { Formik, Field } from 'formik';
import React from 'react';
import {
  Grid,
  Accordion,
  Icon,
  Form,
  Segment,
  Label,
  Input,
  Button,
} from 'semantic-ui-react';
import AwsDatabase from '../../../database/Database';
import { TradeNotations } from '../../../types';
import { getTodayDateInYYYYMMDD } from '../../../utils/calculations/timeCalculation';
import { millisecToSec } from '../../../backtest-node/utils';

const AwsDB = new AwsDatabase();

type ManualBuyFormProps = {
  coinID: string;
  notation: TradeNotations;
  currentPrice?: number;
  paperTrade?: boolean;
  highPrice?: number;
};

const addNewBuyForCoin = (
  coinID: string,
  notation: TradeNotations,
  buyPrice: number,
  buyPercent: number,
  buyTime: string,
  invested: number,
  paperTrade: boolean = false,
  highPrice: number
) => {
  const buyTimeInSec = millisecToSec(new Date(buyTime).getTime());
  const buyColor = '#e0ff0b';
  const realOrderProps = !paperTrade
    ? {
        buyOrderInfo: { amount: +(invested / buyPrice).toFixed(8) },
        sellOrderInfo: null,
        realBuyOrderPlaced: true,
        realBuyOrderStatus: 'closed',
      }
    : undefined;

  AwsDB.createBoughtOrderManual(
    coinID,
    notation,
    buyPrice,
    buyPercent,
    buyTimeInSec,
    buyColor,
    invested,
    highPrice,
    realOrderProps
  );
};
export const AwsManualBuyForm: React.FC<ManualBuyFormProps> = ({
  coinID,
  notation,
  currentPrice,
  paperTrade,
  highPrice,
}) => {
  const [activeIndex, setActiveIndex] = React.useState<
    string | number | undefined
  >(1);

  const handleClick = (
    e: React.MouseEvent,
    titleProps: { index?: string | number | undefined }
  ) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return currentPrice && highPrice ? (
    <Grid.Row compact="true">
      <Grid.Column>
        <Grid columns={1} stackable container>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleClick}
            >
              <Icon name="dropdown" />
              <b>Add Manual Buy ?</b>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Formik
                initialValues={{
                  coinID,
                  notation,
                  currentPrice,
                  buyPercent: 60,
                  buyTime: getTodayDateInYYYYMMDD(),
                  invested: +0.2,
                  paperTrade,
                  highPrice,
                }}
                onSubmit={async (values) => {
                  addNewBuyForCoin(
                    coinID,
                    notation,
                    values.currentPrice,
                    values.buyPercent,
                    values.buyTime,
                    values.invested,
                    values.paperTrade,
                    values.highPrice
                  );
                }}
                validate={(values) => {
                  const errors: {
                    buyPercent?: string;
                    currentPrice?: string;
                    invested?: string;
                    buyTime?: string;
                  } = {};

                  if (!values.currentPrice)
                    errors.currentPrice = 'current price is required';

                  if (!values.buyPercent)
                    errors.buyPercent = 'buy Percent is required';

                  if (!values.invested)
                    errors.invested = 'BTC spent is required';

                  if (!values.buyTime) errors.buyTime = 'Buy time is required';

                  return errors;
                }}
              >
                {(props) => {
                  const {
                    values,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                  } = props;

                  return (
                    <Form onSubmit={handleSubmit}>
                      <Grid columns={5} stackable container>
                        <Grid.Column>
                          <Segment raised>
                            <Label as="div" color="green" ribbon>
                              Buy %
                            </Label>
                            <Input
                              className="width100"
                              transparent
                              id="buyPercent"
                              type="number"
                              placeholder="add Buy percentage"
                              value={values.buyPercent}
                              onChange={handleChange}
                            />
                            {errors.buyPercent && (
                              <Label
                                basic
                                color="red"
                                pointing
                                className="floatRight"
                              >
                                {errors.buyPercent}
                              </Label>
                            )}
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment raised>
                            <Label as="div" color="red" ribbon>
                              Spent
                            </Label>
                            <Input
                              className="width100"
                              transparent
                              id="invested"
                              type="number"
                              placeholder="add BTC Invested"
                              value={values.invested}
                              onChange={handleChange}
                            />
                            {errors.invested && (
                              <Label
                                basic
                                color="red"
                                pointing
                                className="floatRight"
                              >
                                {errors.invested}
                              </Label>
                            )}
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment raised>
                            <Label as="div" color="green" ribbon>
                              Price
                            </Label>
                            <Input
                              className="width100"
                              transparent
                              id="currentPrice"
                              type="number"
                              placeholder="add Buy Price"
                              value={values.currentPrice}
                              onChange={handleChange}
                            />
                            {errors.currentPrice && (
                              <Label
                                basic
                                color="red"
                                pointing
                                className="floatRight"
                              >
                                {errors.currentPrice}
                              </Label>
                            )}
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment raised>
                            <Label as="div" color="orange" ribbon>
                              Time
                            </Label>
                            <Input
                              className="width100"
                              transparent
                              id="buyTime"
                              type="text"
                              placeholder="buyTime"
                              value={values.buyTime}
                              onChange={handleChange}
                            />
                            {errors.buyTime && (
                              <Label
                                basic
                                color="red"
                                pointing
                                className="floatRight"
                              >
                                {errors.buyTime}
                              </Label>
                            )}
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment raised>
                            <Label as="div" color="orange" ribbon>
                              Paper Trade ?
                            </Label>
                            <Field type="checkbox" name="paperTrade" />
                          </Segment>
                        </Grid.Column>
                        <Grid.Column>
                          <Segment basic>
                            <Button
                              type="submit"
                              disabled={isSubmitting}
                              color="green"
                              size="medium"
                            >
                              Save New Buy
                            </Button>
                          </Segment>
                        </Grid.Column>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Accordion.Content>
          </Accordion>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  ) : null;
};
