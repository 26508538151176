import React from 'react';

export type PercentageProps = {
  percentage: number;
  children: React.ReactNode;
};
export const Percentage: React.FC<PercentageProps> = ({
  percentage,
  children,
}) => (
  <span
    style={{
      width: '100%',
      height: '100%',
      borderRadius: '2px',
      color: percentage < 0 ? 'red' : 'green',
      fontWeight: percentage < 0 ? 'normal' : 'bolder',
    }}
  >
    {children}
  </span>
);

export default Percentage;
