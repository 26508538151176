import React from 'react';
import { Portfolio } from '../../API';
import AwsDatabase from '../../database/Database';

const AwsDB = new AwsDatabase();

export const usePortfolio = () => {
  const [portfolio, setPortfolio] = React.useState<Portfolio[]>();

  React.useEffect(() => {
    const getPortfolio = async () => {
      const portfolios = await AwsDB.listPortfolio();
      setPortfolio(portfolios);
    };
    getPortfolio();
  }, []);

  return portfolio;
};
