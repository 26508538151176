import React from 'react';
import AwsDashboard from '../layout/Dashboard';
import { Balances } from '../../types';
import { MainHeader } from '../layout/Header';
import AwsDatabase from '../../database/Database';

const AwsDB = new AwsDatabase();

export type DashboardProps = {};
export const Dashboard: React.FC<DashboardProps> = () => {
  const [btcBalance, setBtcBalance] = React.useState<Balances>();

  const getBalance = () => {
    const getBtcBalance = async () => {
      const balance = await AwsDB.getBalances();
      setBtcBalance(balance);
    };

    getBtcBalance();
  };

  React.useEffect(() => {
    getBalance();
  }, []);

  return (
    <div>
      {btcBalance?.lastUpdated && (
        <MainHeader lastUpdated={btcBalance?.lastUpdated} />
      )}
      <br />
      <AwsDashboard />
    </div>
  );
};
