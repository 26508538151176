/* eslint-disable @typescript-eslint/no-explicit-any */
import { pickBy } from 'lodash';
import {
  Coin,
  CreateOrderBidsInput,
  CreateOrderBoughtsInput,
  OrderInput,
  OrderStatus,
  PairInfo,
} from '../API';

export const pure = (obj: {}) => pickBy(obj, (v) => v !== undefined);

export const transformOrderInfo = (buyOrderInfo: any): OrderInput => ({
  id: buyOrderInfo.id,
  clientOrderId: buyOrderInfo.clientOrderId,
  datetime: buyOrderInfo.datetime,
  timestamp: buyOrderInfo.timestamp,
  lastTradeTimestamp: buyOrderInfo.lastTradeTimestamp,
  status: buyOrderInfo.status,
  symbol: buyOrderInfo.symbol,
  type: buyOrderInfo.type,
  postOnly: buyOrderInfo.postOnly,
  ...(buyOrderInfo.timeInForce && { timeInForce: buyOrderInfo.timeInForce }),
  side: buyOrderInfo.side,
  price: buyOrderInfo.price,
  ...(buyOrderInfo.average && { average: buyOrderInfo.average }),
  amount: buyOrderInfo.amount,
  filled: buyOrderInfo.filled,
  remaining: buyOrderInfo.remaining,
  cost: buyOrderInfo.cost,
});

export const transforCoinInfo = (coin: Coin) => ({
  id: coin.id,
  is_active: coin.is_active,
  is_new: coin.is_new,
  name: coin.name,
  rank: coin.rank,
  symbol: coin.symbol,
  type: coin.type,
  baseType: 'coin',
});

export const transformPairBidOrder = (
  bid: any,
  coinID: string,
  pair: string
): CreateOrderBidsInput => ({
  buyPercent: bid.buyPercent,
  originalBuyPrice: bid.originalBuyPrice,
  buyPrice: bid.buyPrice,
  highPrice: bid.highPrice,
  buyHit: bid.buyHit,
  bidTime: +new Date(),
  ...(bid.invested && { invested: bid.invested }),
  stoploss: bid.stoploss,
  ...(bid.stoplossPrice && { stoplossPrice: bid.stoplossPrice }),
  baseType: 'buyOrder',
  buyColor: bid.buyColor,
  id: bid.id || `${coinID}-${pair}-bids-${bid.buyPercent}-${+new Date()}`,
  pairID: `${coinID}-${pair}`,
  orderBidsPairInfoId: `${coinID}-${pair}`,
});

export const transformPairBoughtOrder = (
  bought: any,
  coinID: string,
  pair: string,
  pairInfoRelativeHigh: number
): CreateOrderBoughtsInput => ({
  id:
    bought.id ||
    `${coinID}-${pair}-boughts-${bought.buyPercent}-${+new Date()}`,
  pairID: `${coinID}-${pair}`,
  highPrice: bought.highPrice ?? pairInfoRelativeHigh,
  buyPercent: bought.buyPercent,
  originalBuyPrice: bought.originalBuyPrice ?? bought.buyPrice,
  buyPrice: bought.buyPrice,
  buyTime: bought.buyTime as unknown as number,
  bidTime: bought.buyTime as unknown as number,
  buyColor: bought.buyColor,
  invested: bought.invested,
  stoploss: bought.stoploss,
  stoplossPrice: bought.stoplossPrice,
  baseType: 'boughtOrder',
  profitSecured: bought.profitSecured,
  profitMoved: bought.profitMoved,
  sellTime: bought.sellTime as unknown as number,
  orderBoughtsPairInfoId: `${coinID}-${pair}`,
  ...(bought.buyOrderInfo && {
    buyOrderInfo: pure(transformOrderInfo(bought.buyOrderInfo)),
  }),
  ...(bought.sellOrderInfo && {
    sellOrderInfo: pure(transformOrderInfo(bought.sellOrderInfo)),
  }),
  ...(bought.realBuyOrderPlaced && {
    realBuyOrderPlaced: bought.realBuyOrderPlaced,
  }),
  ...(bought.realBuyOrderStatus !== undefined && {
    realBuyOrderStatus: bought.realBuyOrderStatus,
  }),
  ...(bought.realSellOrderPlaced && {
    realSellOrderPlaced: bought.realSellOrderPlaced,
  }),
  ...(bought.realSellOrderStatus && {
    realSellOrderStatus: bought.realSellOrderStatus as OrderStatus,
  }),
});

export const transformPairInfo = (
  coinID: string,
  pair: string,
  pairInfo: PairInfo
) => ({
  id: `${coinID}-${pair}`,
  pairInfoCoinId: coinID,
  notation: pair,
  ...(pairInfo.alertPrice && { alertPrice: pairInfo.alertPrice }),
  breakEvenStopLoss: pairInfo.breakEvenStopLoss ?? false,
  currentPrice: pairInfo.currentPrice,
  higestPrice: pairInfo.higestPrice,
  highsAndLows: pairInfo.highsAndLows,
  initialBalance: pairInfo.initialBalance,
  dontTrade: pairInfo?.dontTrade ?? false,
  relativeLow: pairInfo.relativeLow,
  relativeHigh: pairInfo.relativeHigh,
  reservedBalance: pairInfo.reservedBalance,
  baseType: 'pairInfo',
});
