import React from 'react';
import { Step, Icon, Dropdown } from 'semantic-ui-react';
import {
  LayerOption,
  Percentages,
  TradeStatistics,
} from '../../types/backTest';
import { Percentage } from '../SubComponents/Percentage';
import { getHumanReadableDate } from '../../utils/calculations/timeCalculation';
import {
  ACC_START_LAYER_TO_SKIP,
  FIRST_SELL_OFF_START_LAYER_TO_SKIP,
  LAYERS_TO_SKIP,
  TIME_TO_TRADE,
} from '../../config/backTestConfig';
import { getRainbowColor } from '../../utils/utils';

type SelectedLayerDetailsProps = {
  noOfSelectedLayers: number;
  totalProfitOfSelectedLayers: number;
};

type BackTestStatsProps = {
  initialBalance: number;
  currentBalance: number;
  gainLossPercentage: Percentages;
  tradeStatistics: TradeStatistics;
  currentDate: number | undefined;
  isBacktestDone: boolean;
  notation: string;
  selectedLayerDetails: SelectedLayerDetailsProps;

  setShowOption: (option: LayerOption) => void;
};

const layers = [
  { key: 1, color: 'purple' },
  { key: 2, color: 'red' },
  { key: 3, color: 'yellow' },
  { key: 4, color: 'green' },
  { key: 5, color: 'blue' },
];

const dropdownOptions = [
  { key: 'all', text: 'All Trades', value: 'all' },
  ...layers.map((layer) => ({
    key: `layer${layer.key}`,
    text: `Layer ${layer.key}`,
    value: `layer ${layer.key}`,
    label: { color: layer.color },
  })),
  { key: 'incomplete', text: 'Incomplete', value: 'incomplete' },
  { key: 'loss', text: 'Loss', value: 'loss' },
];

export const BackTestStats: React.FC<BackTestStatsProps> = ({
  initialBalance,
  currentBalance,
  gainLossPercentage,
  tradeStatistics,
  currentDate,
  isBacktestDone,
  setShowOption,
  notation,
  selectedLayerDetails,
}) => {
  const humanReadableDate = currentDate
    ? getHumanReadableDate(currentDate)
    : null;

  return (
    <Step.Group fluid className="">
      <Step
        icon={notation === 'usd' ? 'dollar' : 'bitcoin'}
        title={<h2 className="noMargin">{initialBalance}</h2>}
        description="initial Balance"
      />
      <Step
        icon={notation === 'usd' ? 'dollar' : 'bitcoin'}
        title={
          <h2 className="noMargin">
            {currentBalance.toFixed(notation === 'usd' ? 2 : 8)}
          </h2>
        }
        description={
          gainLossPercentage?.percentageChangeWithUnlocked && (
            <>
              <div>current unlocked balance</div>
              <Percentage
                percentage={gainLossPercentage?.percentageChangeWithUnlocked}
              >
                {gainLossPercentage?.percentageChangeWithUnlocked?.toFixed(2)}%
              </Percentage>
            </>
          )
        }
      />
      <Step
        icon="shopping basket"
        description={
          <h1>
            {gainLossPercentage?.PercChangeWithLockedAlso && (
              <Percentage
                percentage={gainLossPercentage.PercChangeWithLockedAlso}
              >
                {gainLossPercentage.PercChangeWithLockedAlso.toFixed(2)}%
              </Percentage>
            )}
          </h1>
        }
        active
        className="center"
      />
      <Step
        icon="lock"
        title={gainLossPercentage?.lockedBalance?.toFixed(2)}
        description="balance locked in trade"
      />
      <Step
        icon="asl"
        title={`${(tradeStatistics.percentageOfSoldTrades || 0).toFixed(
          0
        )}% sold`}
        description={`${tradeStatistics.totalSoldTrades} out of ${tradeStatistics.totalBoughtTrades} trades`}
      />

      <Step
        title={`${LAYERS_TO_SKIP}${FIRST_SELL_OFF_START_LAYER_TO_SKIP}${ACC_START_LAYER_TO_SKIP} layers skipped`}
        description={
          <>
            <Icon name="calendar alternate outline" /> {humanReadableDate} (
            {TIME_TO_TRADE})
          </>
        }
      />
      <Step
        title={`${selectedLayerDetails?.noOfSelectedLayers} trades with ${(
          selectedLayerDetails?.totalProfitOfSelectedLayers /
          selectedLayerDetails?.noOfSelectedLayers
        ).toFixed(2)}% avg profit`}
        description={
          isBacktestDone && (
            <>
              <Dropdown
                selection
                options={dropdownOptions}
                onChange={(_, data) => setShowOption(data.value as LayerOption)}
                style={{ marginTop: '8px' }}
              />
            </>
          )
        }
      />
    </Step.Group>
  );
};
