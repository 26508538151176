import React from 'react';
import { Grid } from 'semantic-ui-react';
import { AwsOrdersTable } from '../SubComponents/tables/CoinOrdersTable';
import { NOTATION } from '../../config/backTestConfig';
import { BackTestCoin } from '../../backtest-node/utils';

type BackTestTableProps = {
  selectedCoinDetails: Record<string, BackTestCoin>;
};

export const BackTestTable: React.FC<BackTestTableProps> = ({
  selectedCoinDetails,
}) => (
  <Grid
    divided="vertically"
    padded
    relaxed
    style={{ justifyContent: 'center', margin: '0 auto' }}
    className="tableContainer"
  >
    {Object.entries(selectedCoinDetails).map(([coinKey, coinDetail]) => (
      <AwsOrdersTable
        key={coinKey}
        currentPrice={coinDetail.currentPrice}
        bids={coinDetail.layeredBuyBids}
        boughts={coinDetail.layeredBuyBoughts}
        compact
        coinName={`${coinDetail.Coin.name} - ${coinDetail.percentageStatistics.averageAlertPercentage}% alert - `}
        isTradePairBtc={NOTATION !== 'usd'}
      />
    ))}
  </Grid>
);
