import { Coin, Portfolio } from '../API';
import AwsDatabase from '../database/Database';

const AwsDB = new AwsDatabase();

const getTradesForNoOfMonths = (trades: any[], monthCount: number) => {
  const cutoffDate = new Date();
  cutoffDate.setMonth(cutoffDate.getMonth() - monthCount);
  const cutoffTime = cutoffDate.getTime();
  return trades.filter((trade) => trade.buyTime > cutoffTime);
};

const sortByDateForSells = (trades: any[]) =>
  trades.sort((a, b) => b.sellTime - a.sellTime);

const sortByDateForBuys = (trades: any[]) =>
  trades.sort((a, b) => b.buyTime - a.buyTime);

export const getSalesData = async () => {
  const portfolio: Portfolio[] = await AwsDB.listPortfolio();
  const allCoinData: Coin[] =
    await AwsDB.getCoinsWithPairAndOrdersForDashboard();

  const layeredBuyBoughtsArray: any[] = [];

  allCoinData.forEach((item) => {
    if (!item?.pairs?.items.length) return;

    item.pairs.items.forEach((pair) => {
      pair?.layeredBuyBoughts?.items.forEach((bought) => {
        layeredBuyBoughtsArray.push({
          ...bought,
          notation: pair.notation,
          id: item.id,
        });
      });
    });
  });

  const soldTrades = layeredBuyBoughtsArray.filter(
    (trade) => trade.sellTime !== null
  );
  const ongoingTrades = layeredBuyBoughtsArray.filter(
    (trade) => trade.sellTime === null
  );

  const soldTradesSorted = sortByDateForSells(soldTrades);
  const ongoingTradesSorted = sortByDateForBuys(ongoingTrades);

  const SoldTradesSixMonthsAgo = getTradesForNoOfMonths(soldTradesSorted, 6);
  const OngoingTradesSixMonthsAgo = getTradesForNoOfMonths(
    ongoingTradesSorted,
    6
  );

  const mostRecentSoldTrades = SoldTradesSixMonthsAgo.slice(0, 5);
  const mostRecentOngoingTrades = OngoingTradesSixMonthsAgo.slice(0, 5);

  const recentTrades = [...mostRecentSoldTrades, ...mostRecentOngoingTrades];

  const tradingPairs = allCoinData.map((coin) => ({
    id: coin.id,
    name: coin.name,
  }));

  console.log('data assembled!');

  return {
    portfolio,
    recentTrades,
    tradingPairs,
  };
};
