/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IPriceLine,
  ISeriesApi,
  SeriesMarker,
  Time,
  UTCTimestamp,
} from 'lightweight-charts';
import { DateTime } from 'luxon';
import { HighLowPrice } from '../API';
import {
  LayeredBuyBidDetails,
  LayeredBoughtDetails,
  TradeNotations,
  LinesInChart,
  DataPropWithTime,
  DataProp,
} from '../types';
import {
  getPercentageDiff,
  getPriceBelowPercentage,
  getProfitGenerated,
  getBEPriceForNotSoldCoins,
  percentageDIffFromHighToLowPrice,
  calculateTotalBalance,
} from '../utils/calculations/percentageAndprice';
import { convertToMillis } from '../utils/calculations/timeCalculation';

export const getTickerData = (ticker: DataPropWithTime) => {
  const newObj = {} as DataPropWithTime;
  Object.values(ticker).forEach((val, index) => {
    if (index === 0) {
      newObj.time = // if val is in milliseconds, convert it to seconds
        (
          val.toString().length > 10 ? (val as number) / 1000 : val
        ) as UTCTimestamp;
    } // adds UNNIX time
    if (index === 3) newObj.open = val as number;
    if (index === 4) newObj.high = val as number;
    if (index === 5) newObj.low = val as number;
    if (index === 6) newObj.close = val as number;

    // FOR BITCOIN 1 day use this
    // if (index === 1) newObj.time = val as UTCTimestamp; // adds UNNIX time
    // if (index === 4) newObj.open = val as number;
    // if (index === 0) newObj.high = val as number;
    // if (index === 5) newObj.low = val as number;
    // if (index === 6) newObj.close = val as number;
  });
  return newObj;
};

export const createPriceLineInChart = (
  candlestickSeries: ISeriesApi<'Candlestick'>,
  priceTobeNoted: number,
  color: string,
  title: string,
  lineStyle = 2
) => {
  const priceLine = candlestickSeries.createPriceLine({
    price: priceTobeNoted,
    color,
    lineWidth: 2,
    lineStyle,
    lineVisible: true,
    axisLabelVisible: true,
    title,
  });
  return priceLine;
};

export const setLowPriceLine = (
  lowFromHighPrice: number,
  linesInChart: LinesInChart,
  candlestickSeries?: ISeriesApi<'Candlestick'>
): IPriceLine | undefined => {
  if (!candlestickSeries) return undefined;

  if (linesInChart.lowPriceLine) {
    candlestickSeries.removePriceLine(linesInChart.lowPriceLine);
    linesInChart.lowPriceLine = undefined;
  }

  linesInChart.lowPriceLine = createPriceLineInChart(
    candlestickSeries,
    lowFromHighPrice,
    'green',
    'low price'
  );

  return linesInChart.lowPriceLine;
};

export const setHighPriceLine = (
  highPrice: number,
  linesInChart: LinesInChart,
  candlestickSeries?: ISeriesApi<'Candlestick'>
): IPriceLine | undefined => {
  if (!candlestickSeries) return undefined;

  if (linesInChart.highPriceLine) {
    candlestickSeries.removePriceLine(linesInChart.highPriceLine);
    linesInChart.highPriceLine = undefined;
  }

  linesInChart.highPriceLine = createPriceLineInChart(
    candlestickSeries,
    highPrice,
    'red',
    'high price'
  );

  return linesInChart.highPriceLine;
};

export const setAlertPriceLine = (
  alertPrice: number,
  linesInChart: LinesInChart,
  percentage: number,
  candlestickSeries?: ISeriesApi<'Candlestick'>
): IPriceLine | undefined => {
  if (!candlestickSeries) return undefined;

  if (linesInChart.alertPriceLine) {
    candlestickSeries.removePriceLine(linesInChart.alertPriceLine);
    linesInChart.alertPriceLine = undefined;
  }

  linesInChart.alertPriceLine = createPriceLineInChart(
    candlestickSeries,
    alertPrice,
    'white',
    `${-percentage.toFixed(0)}% alert price`
  );

  return linesInChart.alertPriceLine;
};

export const setBuyBidsLineInChart = (
  layeredBuyBids: LayeredBuyBidDetails,
  relativeHigh: number,
  priceLines: LinesInChart,
  candlestickSeries: ISeriesApi<'Candlestick'>
) => {
  layeredBuyBids.map((bids) => {
    const {
      buyPrice,
      buyColor,
      buyPercent,
      stoploss,
      stoplossPrice,
      highPrice,
    } = bids;
    const lineMessage = `${buyPercent}% buy bid ${
      stoploss && stoplossPrice
        ? `now ${percentageDIffFromHighToLowPrice(
            highPrice || relativeHigh,
            stoplossPrice
          ).toFixed(1)}% SL`
        : ''
    }`;
    if (priceLines.buyLines && priceLines.buyLines[bids.id]) {
      candlestickSeries.removePriceLine(priceLines.buyLines[bids.id].buyLine!);
      priceLines.buyLines[bids.id].buyLine = undefined;
    }

    const buyLine = createPriceLineInChart(
      candlestickSeries,
      stoploss && stoplossPrice ? stoplossPrice : buyPrice,
      buyColor,
      lineMessage,
      stoploss ? 1 : 2
    );

    priceLines.buyLines = {
      ...priceLines.buyLines,
      [bids.id]: {
        buyLine,
        stopLossLine: undefined,
      },
    };
    return bids;
  });
};

export const setHighPriceAndAlertLine = (
  layeredBuyBids: LayeredBuyBidDetails,
  alertTriggered: boolean,
  highPrice: number,
  alertPercentage: number,
  linesInChart: LinesInChart,
  candleClose?: number,
  candlestickSeries?: ISeriesApi<'Candlestick'>
) => {
  let newLayeredBuyBids = layeredBuyBids;
  let newAlertTriggered = alertTriggered;

  if (linesInChart.alertPriceLine && candlestickSeries) {
    candlestickSeries.removePriceLine(linesInChart.alertPriceLine);
    linesInChart.alertPriceLine = undefined;
    // we might not need alertTriggered value as layeredBuyBids.length should be sufficient to see.
    newAlertTriggered = false;
  }

  if (candlestickSeries) {
    if (linesInChart.highPriceLine) {
      candlestickSeries.removePriceLine(linesInChart.highPriceLine);
      linesInChart.highPriceLine = undefined;
    }
    linesInChart.highPriceLine = createPriceLineInChart(
      candlestickSeries,
      highPrice,
      'red',
      'high price'
    );
  }

  newLayeredBuyBids =
    layeredBuyBids &&
    layeredBuyBids.filter((buys) => {
      if (
        !buys.buyHit &&
        !buys.stoploss &&
        candlestickSeries &&
        linesInChart.buyLines
      ) {
        candlestickSeries.removePriceLine(
          linesInChart.buyLines[buys.id].buyLine!
        );
        linesInChart.buyLines[buys.id].buyLine = undefined;
      }

      return buys.stoploss || buys.buyHit;
    });

  // set alert, 35% OR dynamic % from high price
  // + operator from typescript changes string to number
  let alertPrice: number | null = getPriceBelowPercentage(
    highPrice,
    alertPercentage
  );

  // if the price is already lower than actual alert price when to start bot, set alert 10% below
  if (candleClose && alertPrice > candleClose) {
    alertPrice = null;
  }

  if (
    (!layeredBuyBids && candlestickSeries) ||
    (candlestickSeries &&
      !newAlertTriggered &&
      !layeredBuyBids.length &&
      alertPrice &&
      linesInChart)
  ) {
    linesInChart.alertPriceLine = createPriceLineInChart(
      candlestickSeries,
      alertPrice!,
      'white',
      'alert price'
    );
  }

  return {
    newLayeredBuyBids,
    newAlertTriggered,
    newAlertPrice: alertPrice,
  };
};

const calculatePercentageDIffFromHighAndLow = (
  priceType: string,
  currentPrice: number | undefined,
  index: number,
  lowAndHighPrices: Omit<HighLowPrice, '__typename'>[]
) => {
  const prevPrice = lowAndHighPrices[index - 1]
    ? lowAndHighPrices[index - 1].price
    : null;
  if (!prevPrice) return `${currentPrice}`;

  const value =
    priceType === 'high'
      ? `${currentPrice} - ${getPercentageDiff(currentPrice, prevPrice).toFixed(
          1
        )}% from low`
      : `${currentPrice} - ${getPercentageDiff(currentPrice, prevPrice).toFixed(
          1
        )}% from high`;

  return value;
};

const pushHighsAndLowsToMarker = (
  highsAndLows: Omit<HighLowPrice, '__typename'>[],
  markers: SeriesMarker<Time>[] = [],
  currentTime: number
) => {
  if (highsAndLows.length) {
    highsAndLows.forEach((price, index) => {
      if (convertToMillis(currentTime) > convertToMillis(price.time)) {
        const isoBuyDate = DateTime.fromMillis(
          convertToMillis(price.time)
        ).toISODate();

        markers.push({
          time: isoBuyDate as Time,
          position: price.priceType === 'high' ? 'aboveBar' : 'belowBar',
          color: price.priceType === 'high' ? 'green' : 'white',
          shape: price.priceType === 'high' ? 'arrowDown' : 'arrowUp',
          text: calculatePercentageDIffFromHighAndLow(
            price.priceType,
            price.price,
            index,
            highsAndLows
          ),
        });
      } else {
        console.log('time not added !!!');
      }
    });
  }
  return markers;
};

export const setBreakEvenLine = (
  candlestickSeries: ISeriesApi<'Candlestick'>,
  layeredBuyBoughts: LayeredBoughtDetails,
  currentPrice: number,
  hasBreakEvenStoploss: boolean,
  linesInChart: LinesInChart
) => {
  if (linesInChart.breakEvenLine && candlestickSeries) {
    candlestickSeries.removePriceLine(linesInChart.breakEvenLine);
    linesInChart.breakEvenLine = undefined;
  }

  const breakEvenPrice = getBEPriceForNotSoldCoins(
    currentPrice,
    layeredBuyBoughts
  );

  if (!Number.isNaN(breakEvenPrice)) {
    linesInChart.breakEvenLine = createPriceLineInChart(
      candlestickSeries,
      breakEvenPrice!,
      hasBreakEvenStoploss ? 'orange' : 'yellow',
      hasBreakEvenStoploss ? 'breakeven Stoploss' : 'breakeven price',
      3
    );
  }
};

const getSellText = (
  debugMode: boolean,
  profitSecured: number | null | undefined,
  stoplossPrice: number | null | undefined,
  generatedProfit: number,
  currSymbol: string,
  initialBalance: number,
  runningProfitLoss: number,
  decimalPlace: number,
  reservedBalance: number
) =>
  debugMode
    ? `🏆 at ${
        profitSecured?.toFixed(1) || 'stoploss'
      }% | ${stoplossPrice?.toFixed(
        8
      )} | Profit ${generatedProfit} ${currSymbol} | Rem ${initialBalance} ${currSymbol} | PnL ${runningProfitLoss.toFixed(
        decimalPlace
      )} ${currSymbol} | ®️ ${reservedBalance} ${currSymbol}`
    : `🏆 at ${
        profitSecured?.toFixed(1) || 'stoploss'
      }% | ${stoplossPrice?.toFixed(8)}
        `;

const getBuyText = (
  debugMode: boolean,
  buyPercent: number,
  buyPrice: number,
  invested: number | null | undefined,
  currSymbol: string,
  initialBalance: number,
  decimalPlace: number,
  runningProfitLoss: number,
  reservedBalance: number
) =>
  debugMode
    ? `✅  ${buyPercent}% | @ ${buyPrice} | Inv ${invested} ${currSymbol} | Rem ${initialBalance.toFixed(
        decimalPlace
      )} ${currSymbol} | PnL ${runningProfitLoss} ${currSymbol} | ®️ ${reservedBalance} ${currSymbol}`
    : `✅  @ ${buyPrice}`;

export const getMarkersToBeSet = (
  notation: string,
  layeredBuyBoughts: LayeredBoughtDetails,
  initialBalance: number,
  runningProfitLoss: number,
  reservedBalance: number,
  showPnL?: boolean
) => {
  const currSymbol = notation === 'btc' ? '₿' : '$';
  const decimalPlace = notation === 'btc' ? 8 : 0;
  const markersToBeSet: SeriesMarker<Time>[] = [];
  if (layeredBuyBoughts) {
    layeredBuyBoughts.forEach((buys) => {
      const {
        buyTime,
        buyColor,
        buyPrice,
        buyPercent,
        stoplossPrice,
        profitSecured,
        sellTime,
        invested,
      } = buys;

      const buyText = getBuyText(
        !!showPnL,
        buyPercent,
        buyPrice,
        invested,
        currSymbol,
        initialBalance,
        decimalPlace,
        runningProfitLoss,
        reservedBalance
      );

      const isoBuyDate = DateTime.fromMillis(
        convertToMillis(buyTime)
      ).toISODate();

      markersToBeSet.push({
        time: isoBuyDate as Time,
        position: 'belowBar',
        color: buyColor!,
        shape: 'arrowUp',
        text: buyText,
      });

      if (sellTime) {
        const generatedProfit = profitSecured
          ? getProfitGenerated(profitSecured, invested!)
          : 0;
        const sellText = getSellText(
          !!showPnL,
          profitSecured,
          stoplossPrice,
          generatedProfit,
          currSymbol,
          initialBalance,
          runningProfitLoss,
          decimalPlace,
          reservedBalance
        );

        const isoSellDate = DateTime.fromMillis(
          convertToMillis(sellTime)
        ).toISODate();

        markersToBeSet.push({
          time: isoSellDate as Time,
          position: 'aboveBar',
          color: buyColor!,
          shape: 'arrowDown',
          text: sellText,
          size: 2,
        });
      }
    });
  }
  return markersToBeSet;
};

const sortTimeInAsc = (markersToBeSet: SeriesMarker<Time>[]) =>
  markersToBeSet.sort(
    (a, b) =>
      DateTime.fromISO(a.time as string).toMillis() -
      DateTime.fromISO(b.time as string).toMillis()
  );

export const setMarkerInTime = (
  candlestickSeries: ISeriesApi<'Candlestick'>,
  notation: TradeNotations,
  layeredBuyBoughts: LayeredBoughtDetails,
  initialBalance: number,
  runningProfitLoss: number,
  reservedBalance: number,
  highsAndLows: Omit<HighLowPrice, '__typename'>[],
  currentTime: number, // needed to show the highAndLow in chart
  currentPrice: number,
  showPnL?: boolean,
  showTrades?: boolean
) => {
  if (showTrades) {
    const markersToBeSet: SeriesMarker<Time>[] = getMarkersToBeSet(
      notation,
      layeredBuyBoughts,
      initialBalance,
      runningProfitLoss,
      reservedBalance,
      showPnL
    );
    const ascMarkers = sortTimeInAsc(markersToBeSet);

    if (markersToBeSet.length) {
      candlestickSeries.setMarkers(ascMarkers);
    }

    return;
  }

  const markersToBeSet = pushHighsAndLowsToMarker(
    highsAndLows,
    [],
    currentTime
  );
  const ascMarkers = sortTimeInAsc(markersToBeSet);

  if (markersToBeSet.length) {
    candlestickSeries.setMarkers(ascMarkers);
  }
};

export const setMarkerForLowAndHighPriceInTime = (
  candlestickSeries: ISeriesApi<'Candlestick'>,
  lowAndHighPrices: Omit<HighLowPrice, '__typename'>[],
  currentTime: number
) => {
  const markersToBeSet = pushHighsAndLowsToMarker(
    lowAndHighPrices,
    [],
    currentTime
  );

  candlestickSeries.setMarkers(markersToBeSet);
};

export const showTradesAndBreakeven = (
  candlestickSeries: ISeriesApi<'Candlestick'>,
  INITIAL_BALANCE: number,
  RESERVED_BALANCE: number,
  notation: string,
  layeredBuyBoughts: LayeredBoughtDetails,
  sanitizedData: DataPropWithTime,
  hasBreakEvenStoploss: boolean,
  linesInChart: LinesInChart,
  highsAndLows: Omit<HighLowPrice, '__typename'>[]
) => {
  setBreakEvenLine(
    candlestickSeries!,
    layeredBuyBoughts,
    sanitizedData.close,
    hasBreakEvenStoploss,
    linesInChart
  );

  // running Profit & loss includes the remaining balance! + not sold (coins in trade)
  const runningProfitLoss = calculateTotalBalance(
    sanitizedData.close,
    INITIAL_BALANCE,
    layeredBuyBoughts
  );

  // set marker with new profits
  // TODO move it somewhere so it does not get called all the time!!!
  setMarkerInTime(
    candlestickSeries!,
    notation === 'btcPair' ? 'btc' : 'usd',
    layeredBuyBoughts,
    INITIAL_BALANCE,
    runningProfitLoss,
    RESERVED_BALANCE,
    highsAndLows,
    (sanitizedData as DataProp).time,
    sanitizedData.close,
    true,
    true
  );
};
