import React, { useEffect, useRef } from 'react';
import {
  createChart,
  LineData,
  IChartApi,
  Time,
  LineStyle,
} from 'lightweight-charts';
import { Portfolio } from '../../API';
import {
  chartLayoutStyleDark,
  timeScale,
} from '../../chart-specifics/create-chart';
import { millisecToSec } from '../../backtest-node/utils';

type NormalizedData = {
  date: Time;
  normalizedPortfolioValueUSD: number;
  normalizedTotalMarketCapUSD: number;
  portfolioValueUSD: number;
  totalMarketCapUSD: number;
};

const normalizeData = (data?: Portfolio[]): NormalizedData[] => {
  if (!data || (data && data.length) === 0) return [];

  data.sort((a, b) => a.date - b.date);

  const initialPortfolioValueUSD = data[0].portfolioValueUSD;
  const initialTotalMarketCapUSD = data[0].totalMarketCapUSD;

  return data.map((entry) => ({
    date: millisecToSec(entry.date) as Time,
    normalizedPortfolioValueUSD:
      (entry.portfolioValueUSD / initialPortfolioValueUSD) * 100 - 100, // Subtract 100 here to make 0 % the starting point
    normalizedTotalMarketCapUSD:
      (entry.totalMarketCapUSD / initialTotalMarketCapUSD) * 100 - 100,
    portfolioValueUSD: entry.portfolioValueUSD,
    totalMarketCapUSD: entry.totalMarketCapUSD,
  }));
};

const getLastValue = (lineData: LineData[]): string | undefined =>
  `${lineData[lineData.length - 1].value.toFixed(2)}%`;

const PortfolioChart: React.FC<{
  data: Portfolio[];
  width?: string;
  backgroundColor?: string;
}> = ({ data, width = '100', backgroundColor = '#000000' }) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const portfolioWidth =
      (chartContainerRef.current.clientWidth * Number(width)) / 100;

    const chart = createChart(chartContainerRef.current, {
      width: portfolioWidth,
      height: 460,
      timeScale,
      rightPriceScale: {
        visible: false,
      },
    });
    chart.applyOptions(chartLayoutStyleDark(backgroundColor));

    chartRef.current = chart;

    const normalizedData: NormalizedData[] = normalizeData(data);

    const portfolioLineData: LineData[] = normalizedData.map((entry) => ({
      time: entry.date,
      value: entry.normalizedPortfolioValueUSD,
    }));

    const marketCapLineData: LineData[] = normalizedData.map((entry) => ({
      time: entry.date,
      value: entry.normalizedTotalMarketCapUSD,
    }));

    const portfolioSeries = chart.addLineSeries({
      priceScaleId: 'right',
      title: `Portfolio value ${getLastValue(portfolioLineData)}`,
      color: '#E3CC8C',
      lineWidth: 2,
      priceLineVisible: false,
      lastValueVisible: true,
      priceFormat: {
        type: 'percent',
      },
    });

    const marketCapSeries = chart.addLineSeries({
      priceScaleId: 'right',
      title: `Market cap ${getLastValue(marketCapLineData)}`,
      color: '#A22B1C',
      lineWidth: 2,
      lineStyle: LineStyle.SparseDotted,
      priceLineVisible: false,
    });

    portfolioSeries.setData(portfolioLineData);
    marketCapSeries.setData(marketCapLineData);

    // Set visible range to show the last 30 days
    const NO_OF_DAYS = 180;
    if (normalizedData.length > 0) {
      const millisecondsInADay = 24 * 60 * 60;
      const lastDateTimeInRange = new Date(
        normalizedData[normalizedData.length - 1].date as number
      ).getTime();

      const startTime = lastDateTimeInRange - NO_OF_DAYS * millisecondsInADay;
      const endTime = lastDateTimeInRange;

      chart.timeScale().setVisibleRange({
        from: startTime as Time,
        to: endTime as Time,
      });
    }

    // eslint-disable-next-line consistent-return
    return () => chart.remove();
  }, [data]);

  return (
    <div
      className="PortfolioChart"
      ref={chartContainerRef}
      style={{
        position: 'relative',
        width: '100%',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    />
  );
};

export default PortfolioChart;
