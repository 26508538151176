import React from 'react';
import { TextArea, Button, Header, Segment, Divider } from 'semantic-ui-react';
import { useAllCoinData } from '../../hooks/useAllCoinData';
import {
  addNewFiftyCoinsToDB,
  deleteDontTradeCoinsFromDB,
  refreshRankingsFromCMC,
} from '../../../utils/coinManagementOperations';

export const CoinManagementForm: React.FC = () => {
  const [newCoins, setNewCoins] = React.useState<string>('');
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [isAddingCoins, setIsAddingCoins] = React.useState(false);
  const { data } = useAllCoinData();

  const handleAddCoins = async () => {
    const coinsArray = newCoins
      .split('\n')
      .map((coin) => coin.trim())
      .filter((coin) => coin !== '');

    if (coinsArray.length === 0) {
      alert('Please enter at least one coin ID');
      return;
    }

    setIsAddingCoins(true);
    try {
      const addedCoins = await addNewFiftyCoinsToDB(data, coinsArray);

      if (addedCoins.length > 0) {
        alert(
          `Successfully added ${addedCoins.length} coins with their high/low values!`
        );
      } else {
        alert(
          'No new coins were added. They might already exist in the database.'
        );
      }

      setNewCoins('');
    } catch (error) {
      console.error('Error adding coins:', error);
      alert('Error adding coins. Check console for details.');
    } finally {
      setIsAddingCoins(false);
    }
  };

  const handleDeleteDontTradeCoins = async () => {
    if (
      window.confirm('Are you sure you want to delete all dont trade coins?')
    ) {
      try {
        await deleteDontTradeCoinsFromDB(data);
        alert('Dont trade coins deleted successfully!');
      } catch (error) {
        console.error('Error deleting coins:', error);
        alert('Error deleting coins. Check console for details.');
      }
    }
  };

  const handleRefreshRankings = async () => {
    if (!data) {
      alert('No coins data available');
      return;
    }

    setIsRefreshing(true);
    try {
      const updatedCoins = await refreshRankingsFromCMC(data);
      if (updatedCoins.length > 0) {
        alert(
          `Successfully updated rankings for ${updatedCoins.length} coins!`
        );
      } else {
        alert('All coin rankings are already up to date.');
      }
    } catch (error) {
      console.error('Error refreshing rankings:', error);
      alert('Error refreshing rankings. Check console for details.');
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <Segment>
      <Button
        color="teal"
        onClick={handleRefreshRankings}
        loading={isRefreshing}
        disabled={isRefreshing}
      >
        Refresh Coin Rankings
      </Button>
      <Divider />
      <TextArea
        placeholder="Enter coin IDs (one per line) e.g.:
sol-solana
avax-avalanche
matic-polygon"
        value={newCoins}
        onChange={(e) => setNewCoins(e.target.value)}
        rows={4}
        style={{ width: '50%', marginBottom: '1rem' }}
      />
      <div style={{ marginTop: '1rem' }}>
        <Button primary loading={isAddingCoins} onClick={handleAddCoins}>
          Add New Coins
        </Button>
        <Button negative onClick={handleDeleteDontTradeCoins} disabled>
          Delete Don't Trade Coins
        </Button>
      </div>
    </Segment>
  );
};
