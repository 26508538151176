import { MarketCycle } from '../types';

// 18 coins
export const FIRST_BEAR_COINS_TO_TEST = [
  'xrp-xrp',
  'ltc-litecoin',
  'eth-ethereum',
  'bch-bitcoin-cash',
  'xmr-monero',
  'etc-ethereum-classic',
  'zec-zcash',
  'dash-dash',
  'ada-cardano',
  'eos-eos',
  'doge-dogecoin',
  'mana-decentraland',
  'trx-tron',
  'link-chainlink',
  'xtz-tezos',
  'mkr-maker',
  'fil-filecoin',
  'xlm-stellar',
];

// 6 coins
export const COINS_WITH_ONLY_SEC_BEAR_DATA = [
  // only has data for second bear market
  'atom-cosmos',
  'matic-polygon',
  'snx-synthetix-network-token',
  'uni-uniswap',
  'aave-new',
  'dot-polkadot',
];

export const ALL_COINS_TO_TEST = [
  ...FIRST_BEAR_COINS_TO_TEST,
  ...COINS_WITH_ONLY_SEC_BEAR_DATA,
];

// export const ALL_COINS_TO_TEST = ['fil-filecoin'];

export const NOTATION: 'usd' | 'btc' = 'usd';
export const MARKET_CYCLE: MarketCycle = 'bear';
// @ts-ignore
export const INITIAL_BALANCE = NOTATION === 'btc' ? 2 : 200_000;
export const TIME_TO_TRADE: 'hour' | 'day' = 'hour';
export const SHOW_TABLE = true;
export const SHOW_CHART_AT_ONCE = false;
export const CHART_TO_SHOW =
  ALL_COINS_TO_TEST.length === 1 ? ALL_COINS_TO_TEST[0] : null;
// export const CHART_TO_SHOW = null;

export const LAYERS_TO_SKIP = 2;
export const FIRST_SELL_OFF_START_LAYER_TO_SKIP = 4;
export const ACC_START_LAYER_TO_SKIP = 0;

export const FIRST_BEAR_START_DATE = 1513209600;
export const FIRST_BEAR_END_DATE = 1593993600;
export const FIRST_BEAR_SELL_OFF_START_DATE = 1525305600;
export const FIRST_BEAR_ACCUMULATION_START_DATE = 1544832000;
// ------------------- 2018 FIRST BEAR MARKET -------------------
// | Timestamp    | Date and Time           | Event Description                                      |
// |--------------|-------------------------|--------------------------------------------------------|
// | 1513209600   | 2017-12-14 00:00:00     | Start of bear market                                   |
// | 1525305600   | 2018-05-03 00:00:00     | Violent market selloff starts                          |
// | 1544832000   | 2018-12-15 00:00:00     | Accumulation starts / First selloff ends/ BOTTOM is in |
// | 1593993600   | 2020-07-06 00:00:00     | Accumulation ends - full bear market                   |

export const SEC_BULL_STARTS = 1601816400; // Oct 04 2020 in seconds
export const SEC_BULL_MARKET_TOP = 1620777600; // 12th May 2021 in seconds

export const SEC_BEAR_START_DATE = 1619827200;
export const SEC_BEAR_END_DATE = 1699488000;
export const SEC_BEAR_SELL_OFF_START_DATE = 1648771200;
export const SEC_BEAR_ACCUMULATION_START_DATE = 1655596800;
// ------------------- 2021 SECOND BEAR MARKET -------------------
// | Timestamp    | Date and Time           | Event Description                                      |
// |--------------|-------------------------|--------------------------------------------------------|
// | 1619827200   | 2021-05-01 00:00:00     | Start of bear market                                   |
// | 1648771200   | 2022-04-01 00:00:00     | Violent market selloff starts                          |
// | 1655596800   | 2022-06-19 00:00:00     | Accumulation starts / First selloff ends               |
// | 1699488000   | 2023-11-09 00:00:00     | Accumulation ends with a bang! / BOTTOM is in          |
// ------------------- ------------------- -------------------
// | 1724112000   | 2024-08-20 00:00:00     | Final date until we have data                          |
